import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Select, MenuItem, Radio, RadioGroup, FormGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import { Send, ExpandMore } from '@material-ui/icons';
import { useTranslation } from "react-i18next";
import { useMinimalSelectStyles } from '@mui-treasury/styles/select/minimal';
import "../CSS/AddTodoScreen.css";
import "react-datepicker/dist/react-datepicker.css";
import { addTodo, updateTodo } from '../../../../actions/todoActions';

import { connect } from 'react-redux';
import moment from 'moment';

import { DatePicker, LocalizationProvider } from "@material-ui/pickers"
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns"

import { numberStepIntro } from '../../../../actions/authActions'
import { getPoints } from '../../../../actions/pointActions'
import { getWeeklyGoals } from '../../../../actions/weeklyGoalActions'
import MomentAdapter from "@material-ui/pickers/adapter/moment";

const AddTodoScreen = ({ todo, setTodo, history, addTodo, updateTodo, getPoints, locationComponent, activeStep, setActiveStep, numberStepIntro, openBackDrop, setOpenBackdrop, isAddComponent }) => {
    const { t, i18n } = useTranslation("");
    const auth = useSelector(state => state.auth.user);
    const weeklyGoals = useSelector(state => state.weeklyGoal);
    const todos = useSelector(state => state.todos);

    const [recurrenceTypeArray, setRecurrenceTypeArray] = useState([
        {
            type: "never",
            name: "Ne pas répéter",
            endRepeat: new Date(auth.endDayChallenge, "DD/MM/YYYY")
        },
        {
            type: "daily",
            name: "Tous les jours",
            endRepeat: new Date(auth.endDayChallenge, "DD/MM/YYYY")
        },
        {
            type: "weekly",
            name: "Toutes les semaines",
            endRepeat: new Date(auth.endDayChallenge, "DD/MM/YYYY")
        },
        {
            type: "monthly",
            name: "Tous les mois",
            endRepeat: new Date(auth.endDayChallenge, "DD/MM/YYYY")
        },
    ])

    const priorityNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

    const [recuType, setRecuType] = useState("never");
    const [recuEndDate, setRecuEndDate] = useState(new Date());

    const minimalSelectClasses = useMinimalSelectStyles();
    const iconComponent = (props) => {
        return (
            <ExpandMore className={props.className + " " + minimalSelectClasses.icon} />
        )
    };
    const menuProps = {
        classes: {
            paper: minimalSelectClasses.paper,
            list: minimalSelectClasses.list
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        // Check if it's an update or a add (if _id exists, then it's an update)
        if (todo._id) {
            const id = todo._id
            const updatedTodo = {
                name: todo.name,
                estimatedDuration: todo.estimatedDuration,
                isCompleted: todo.isCompleted,
                todoDate: moment(todo.todoDate, "DD/MM/YYYY").format("DD/MM/YYYY").toString(),
                weeklyGoal: todo.weeklyGoal,
                comment: todo.comment,
                priority: todo.priority,
            }

            /*setTodo({
                name: todo.name,
                estimatedDuration: todo.estimatedDuration,
                isCompleted: false,
                todoDate: todo.todoDate,
                weeklyGoal: todo.weeklyGoal
            })*/

            updateTodo(updatedTodo, id);
        } else {
            /*setTodo({
                name: todo.name,
                estimatedDuration: todo.estimatedDuration,
                isCompleted: false,
                todoDate: todo.todoDate,
                weeklyGoal: todo.weeklyGoal
            })*/


            switch (recuType) {
                case "daily":
                    let newTodoDaily = {
                        name: todo.name,
                        estimatedDuration: todo.estimatedDuration,
                        isCompleted: todo.isCompleted,
                        todoDate: todo.todoDate,
                        weeklyGoal: todo.weeklyGoal,
                        comment: todo.comment,
                        weekToTodo: false,
                        priority: todo.priority,
                    }

                    addTodo(newTodoDaily, 1, recuEndDate);
                    setTimeout(function () { getPoints(auth._id) }, 5000);
                    break;
                case "weekly":
                    let newTodoWeekly = {
                        name: todo.name,
                        estimatedDuration: todo.estimatedDuration,
                        isCompleted: todo.isCompleted,
                        todoDate: todo.todoDate,
                        weeklyGoal: todo.weeklyGoal,
                        comment: todo.comment,
                        weekToTodo: false,
                        priority: todo.priority,
                    }

                    addTodo(newTodoWeekly, 2, recuEndDate);
                    setTimeout(function () { getPoints(auth._id) }, 5000);
                    break;
                case "monthly":
                    let newTodoMonthly = {
                        name: todo.name,
                        estimatedDuration: todo.estimatedDuration,
                        isCompleted: todo.isCompleted,
                        todoDate: todo.todoDate,
                        weeklyGoal: todo.weeklyGoal,
                        comment: todo.comment,
                        weekToTodo: false,
                        priority: todo.priority,
                    }

                    addTodo(newTodoMonthly, 3, recuEndDate);
                    setTimeout(function () { getPoints(auth._id) }, 5000);
                    break;
                case "never":
                    let newTodoNever = {
                        name: todo.name,
                        estimatedDuration: todo.estimatedDuration,
                        isCompleted: todo.isCompleted,
                        todoDate: todo.todoDate,
                        weeklyGoal: todo.weeklyGoal,
                        comment: todo.comment,
                        weekToTodo: false,
                        priority: todo.priority,
                    }

                    addTodo(newTodoNever, 0, recuEndDate);
                    setTimeout(function () { getPoints(auth._id) }, 5000);
                    break;
                default:
                    break;
            }

            /*const newTodo = {
                name: todo.name,
                estimatedDuration: todo.estimatedDuration,
                isCompleted: todo.isCompleted,
                todoDate: moment(todo.todoDate).format("DD/MM/YYYY").toString(),
                weeklyGoal: todo.weeklyGoal,
                comment: todo.comment
            }*/

            // Dispatch the todo using the action created in store
            /*addTodo(newTodo);
            setTimeout(function () { getPoints(auth._id) }, 5000);*/

        }

        // Reset todo values
        /*setTodo({
            name: "",
            estimatedDuration: "",
            isCompleted: false,
            todoDate: new Date(),
            weeklyGoal: ""
        })*/

        setTimeout(() => {
            if (locationComponent == "Intro") {
                setActiveStep((activeStep) => activeStep + 2);
                const updatedStep = {
                    _id: auth._id,
                    stepIntro: 7,
                };
                numberStepIntro(updatedStep);
            } else {
                const oneModaltodo = document.querySelector(".modal__views");
                oneModaltodo.classList.remove("active-modal");
            }
        }, 2000);
    }

    useEffect(() => {
        getWeeklyGoals();
    }, []);

    useEffect(() => {
        if (isAddComponent === true) {
            setTodo({
                name: "",
                estimatedDuration: "",
                isCompleted: false,
                todoDate: new Date(),
                weeklyGoal: "",
                comment: "",
                priority: 15,
            });
        }
    }, [isAddComponent, todos]);

    const handleRecuTypeChange = (e) => {
        setRecuType(e.target.value);
    }

    return (
        <div>
            <form autoComplete="off" className="todo__add-Form" onSubmit={submitHandler}>
                <div className="todo__input">
                    <TextField
                        id="todoName"
                        label="Tache à faire"
                        variant="outlined"
                        autoFocus
                        fullWidth
                        value={todo.name}
                        onChange={(e) => setTodo({ ...todo, name: e.target.value })}
                        required
                    />
                </div>
                <div className="todo__input">
                    <TextField
                        id="estimatedDuration"
                        label="Temps estimé en minutes"
                        variant="outlined"
                        type="number"
                        fullWidth
                        min="0"
                        stpe="1"
                        value={todo.estimatedDuration}
                        onChange={(e) => setTodo({ ...todo, estimatedDuration: Math.round(e.target.value) })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />
                </div>
                <div className="todo__input">
                    <TextField
                        id="todoComment"
                        label="Commentaire"
                        multiline
                        rowsMax="3"
                        value={todo.comment}
                        onChange={(e) => setTodo({ ...todo, comment: e.target.value })}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                </div>

                <div className="todo__input">
                    <LocalizationProvider dateAdapter={MomentAdapter}>
                        <DatePicker
                            minDate={new Date()}
                            label="Choisissez une date"
                            value={moment(todo.todoDate, "DD/MM/YYYY").toDate()}
                            onChange={(newValue) => {
                                setTodo({ ...todo, todoDate: newValue })
                                setRecuEndDate(newValue)
                            }}
                            renderInput={(props) => <TextField {...props} helperText="jj/mm/aaaa" />}
                        />
                    </LocalizationProvider>
                </div>

                {recuType == "never" &&
                    <>
                        <label className="contact__label">Choisissez une semaine pour cet objectif (facultatif)</label>
                        <Select
                            disableUnderline
                            classes={{ root: minimalSelectClasses.select }}
                            MenuProps={menuProps}
                            IconComponent={iconComponent}
                            style={{ minWidth: 100 }}
                            value={todo.weeklyGoal}
                            onChange={(e) => setTodo({ ...todo, weeklyGoal: e.target.value })}
                        >
                            {weeklyGoals && weeklyGoals.map(wg => {
                                return (
                                    <MenuItem
                                        value={wg._id}
                                        className={(wg.trimester == auth.currentTrimester && wg.week == auth.currentWeek) ? "sameTrimester" : "notSameTrimester"}
                                        disabled={wg.isCompleted}
                                    >{wg.estimatedNumberedGoal} {wg.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </>
                }

                <label className="contact__label">Priorité de cette tache</label>
                <Select
                    disableUnderline
                    classes={{ root: minimalSelectClasses.select }}
                    MenuProps={menuProps}
                    IconComponent={iconComponent}
                    style={{ minWidth: 100 }}
                    value={todo.priority}
                    onChange={(e) => setTodo({ ...todo, priority: e.target.value })}
                >
                    {priorityNumber && priorityNumber.map(pn => {
                        return (
                            <MenuItem
                                value={pn}
                                className="sameTrimester"
                            >{pn}</MenuItem>
                        )
                    })}
                </Select>

                <div className="todo__input">
                    <label className="contact__label">Répéter</label>
                    <div>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="recurrenceTypeArray"
                                defaultValue="never"
                                value={recuType}
                                name="row-radio-buttons-group"
                                onChange={handleRecuTypeChange}
                                row
                            >
                                {recurrenceTypeArray.map((rt) => {
                                    return (
                                        <FormControlLabel
                                            value={rt.type}
                                            control={
                                                <Radio />
                                            }
                                            label={rt.name}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>

                {recuType != "never" &&
                    <div className="todo__input">
                        <LocalizationProvider dateAdapter={MomentAdapter}>
                            <DatePicker
                                minDate={new Date()}
                                label="Choisissez une date de fin"
                                value={recuEndDate}
                                onChange={(newValue) => {
                                    setRecuEndDate(newValue)
                                }}
                                renderInput={(props) => <TextField {...props} helperText="jj/mm/aaaa" />}
                            />
                        </LocalizationProvider>
                    </div>
                }

                <Button variant="contained" color="primary" className="submitButton" type="submit">
                    <Send />
                </Button>
            </form>
        </div>
    )
}

export default connect(null, { getWeeklyGoals, addTodo, updateTodo, numberStepIntro, getPoints })(AddTodoScreen);