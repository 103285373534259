import axios from 'axios';
import { toast } from 'react-toastify';
import { setHeaders } from '../api/headers';

// Get points
export const getPoints = (id) => (dispatch, getState) => {
    const token = getState().auth.user.token
    //const id = getState().auth.user._id
    axios.get(`/api/point/points/${id}`, setHeaders(token))
        .then(points => {
            dispatch({
                type: "GET_POINTS",
                points
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}