import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import todoReducer from './todoReducers';
import todoTodayReducer from './todoTodayReducers';
import yearlyGoalReducer from './yearlyGoalReducers';
import trimesterGoalReducer from './trimesterGoalReducers';
import weeklyGoalReducer from './weeklyGoalReducers';
import authReducer from './authReducers';
import pointReducer from './pointReducers';
import tipsToolsReducer from './tipsToolsReducers';
import routineReducer from './routineReducers';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'todos', 'todosToday', 'yearlyGoal', 'trimesterGoal', 'weeklyGoal', 'points', 'tipsTools', 'routines']
}

const rootReducer = combineReducers({
    todos: todoReducer,
    todosToday: todoTodayReducer,
    yearlyGoal: yearlyGoalReducer,
    trimesterGoal: trimesterGoalReducer,
    weeklyGoal: weeklyGoalReducer,
    points: pointReducer,
    auth: authReducer,
    tipsTools: tipsToolsReducer,
    routines: routineReducer,
})

export default persistReducer(persistConfig, rootReducer);