import axios from 'axios';
import { toast } from 'react-toastify';
import { setHeaders } from '../api/headers';

// Get all yearly goals
export const getYearlyGoals = () => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get('/api/goal/allyearlygoals', setHeaders(token))
        .then(goals => {
            dispatch({
                type: "GET_YEARLY_GOALS",
                goals
            })
        })
        .catch(error => {
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}

// Get all yearly goals with ID
export const getYearlyGoalsWithID = (id) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get(`/api/goal/allyearlygoals/${id}`, setHeaders(token))
        .then(goals => {
            dispatch({
                type: "GET_YEARLY_GOALS_WITH_ID",
                goals
            })
        })
        .catch(error => {
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Create a new yearly
export const addYearlyGoal = (yearlyGoal, type) => {
    return (dispatch, getState) => {
        const uid = getState().auth.user._id;
        const token = getState().auth.user.token;

        axios.post(`/api/goal/creategoal/${type}`, { ...yearlyGoal, uid }, setHeaders(token))
            .then(goal => {
                dispatch({
                    type: "ADD_YEARLY_GOAL",
                    goal
                })
            })
            .catch(error => {
                toast.error(error.response?.data, {
                    position: toast.POSITION.BOTTON_RIGHT
                });
            })
    }
}

// Update a yearly goal
export const updateYearlyGoal = (updatedYearlyGoal, id, type) => {
    return (dispatch, getState) => {
        const token = getState().auth.user.token
        axios.put(`/api/goal/updategoal/${type}/${id}`, updatedYearlyGoal, setHeaders(token))
            .then(goal => {
                dispatch({
                    type: "UPDATE_YEARLY_GOAL",
                    goal
                })
            })
            .catch(error => {
                toast.error(error.response?.data, {
                    position: toast.POSITION.BOTTON_RIGHT
                });
            })
    }
}

// Delete a todo
export const deleteYearlyGoal = (id, type) => {
    return (dispatch, getState) => {
        const token = getState().auth.user.token
        axios.delete(`/api/goal/deletegoal/${type}/${id}`, setHeaders(token))
            .then((goal) => {
                dispatch({
                    type: "DELETE_YEARLY_GOAL",
                    id,
                    goal
                })
            })
            .catch(error => {
                toast.error(error.response?.data, {
                    position: toast.POSITION.BOTTON_RIGHT
                });
            })
    }
}

