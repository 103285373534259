import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import 'moment/locale/fr';
import moment from 'moment';

import TodoScreen from "./TodoScreen";
import "../CSS/ListTodosScreen.css";
import { connect } from 'react-redux';

import { getTodos, getTodosStatus, updateAllTodos } from '../../../../actions/todoActions';
import { getTodosStatusToday } from '../../../../actions/todoTodayActions';
import { getWeeklyGoals } from '../../../../actions/weeklyGoalActions';

//new date picker
import TextField from "@material-ui/core/TextField";

import {
    DateRangePicker,
    MobileDateRangePicker,
    DateRangeDelimiter,
    LocalizationProvider,
} from "@material-ui/pickers";

import MomentAdapter from "@material-ui/pickers/adapter/moment";

import DateFnsUtils from "@material-ui/pickers/adapter/date-fns"
import fr from "date-fns/locale/fr";

//buttons
import Button from '@material-ui/core/Button';

//select
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//input
import InputAdornment from '@material-ui/core/InputAdornment';

//theme
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { lightBlue } from "@material-ui/core/colors";

//icon
import SearchIcon from '@material-ui/icons/Search';

const ListTodosScreen = ({ setTodo, getTodos, updateAllTodos, getTodosStatus, getTodosStatusToday, getWeeklyGoals, openBackDrop, setOpenBackdrop, setIsAddComponent, setOpenConfirmation, setIdToDelete }) => {
    const todos = useSelector((state) => state.todos);
    let [todosFiltered, setTodosFiltered] = useState([]);
    const todosToday = useSelector((state) => state.todosToday);
    const weeklyGoals = useSelector(state => state.weeklyGoal);
    const [taskStatus, setTaskStatus] = useState(3);
    const [taskStatusToday, setTaskStatusToday] = useState(3);
    const orientation = window.matchMedia("(max-width: 700px)").matches ? 'vertical' : 'horizontal'
    const tasksStatus = [
        { id: 1, name: "Tous " }, //All
        { id: 2, name: "Complété " }, //Completed
        { id: 3, name: "A faire " } //To be completed
    ]
    const [dateSelectionRange, setDateSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })

    const [valueDateRange, setValueDateRange] = useState([new Date(), new Date()]);
    const [countTotalMinutes, setCountTotalMinutes] = useState();

    //toogle 
    const [showDRP, setShowDRP] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    function filteredTodo(status, start, end, searchTerm) {
        const from = new Date(start);
        const to = new Date(end);

        let filteredTodoResult;
        let arrayDateInterval = [];
        let dateToCompare = from;

        do {
            arrayDateInterval.push(moment(dateToCompare).format("DD/MM/YYYY").toString());
            dateToCompare = moment(dateToCompare).add(1, 'days');
        } while (dateToCompare <= to)

        if (status == 1) {
            filteredTodoResult = todos.filter((todo) => {
                return arrayDateInterval.includes(todo.todoDate);
            });
        } else if (status == 2) {
            filteredTodoResult = todos.filter((todo) => {
                return arrayDateInterval.includes(todo.todoDate) && todo.isCompleted;
            });
        } else {
            filteredTodoResult = todos.filter((todo) => {
                return arrayDateInterval.includes(todo.todoDate) && !todo.isCompleted;
            });
        }
    
        if (searchTerm != undefined) {
            filteredTodoResult = filteredTodoResult.filter((todo) => {
                return todo.name.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }

        setTodosFiltered(filteredTodoResult);

        totalMinutes(filteredTodoResult);
    }

    function totalMinutes(filteredTodoResult) {
        let minutes = 0;
        if (filteredTodoResult.length > 0) {
            filteredTodoResult.map((todo) => {
                minutes = minutes + todo.estimatedDuration;
            })
        }

        setCountTotalMinutes(minutes);
    }

    let searchItemHandler = (e) => {
        setSearchTerm(e.target.value);
        filteredTodo(taskStatus, valueDateRange[0], valueDateRange[1], e.target.value);
    };

    useEffect(() => {
        //getTodosStatus(3, valueDateRange[0], valueDateRange[1]);
        updateAllTodos();
        filteredTodo(3, valueDateRange[0], valueDateRange[1], searchTerm);
        //totalMinutes();
    }, [])

    useEffect(() => {
        filteredTodo(3, valueDateRange[0], valueDateRange[1], searchTerm);
        getTodosStatusToday(taskStatusToday);
        getWeeklyGoals();
        //totalMinutes();
    }, [valueDateRange])

    //filter
    const handleChange = (event) => {
        setTaskStatus(event.target.value);
        filteredTodo(event.target.value, valueDateRange[0], valueDateRange[1], searchTerm);
        //totalMinutes();
        //getTodosStatus(event.target.value, valueDateRange[0], valueDateRange[1]);
    };
    moment.locale('fr');

    //removebackdrop
    useEffect(() => {
        setOpenBackdrop(false);
        filteredTodo(taskStatus, valueDateRange[0], valueDateRange[1], searchTerm);
        //totalMinutes();
    }, [todos, todosToday])

    //set backdrop
    useEffect(() => {
        setOpenBackdrop(true);
    }, [])


    return (

        <div className="todo__content grid">

            <div>
                <div className="todo__content--header">
                    <div className="todo__content--headerLeft">

                        {moment(valueDateRange[0]).format('Do MM YY') == moment(new Date()).format('Do MM YY') ? (
                            moment(valueDateRange[1]).format('Do MM YY') == moment(new Date()).format('Do MM YY') ? (
                                <>
                                    <span className="todo__content--headerTitle">Aujourd'hui</span>
                                    <span className="todo__content--headerSubtitle">{todosFiltered.length > 0 ? "Taches journalières" : "Aucune tache journalière "}</span>
                                </>
                            )
                                :
                                (<></>)
                        ) :
                            (
                                <>
                                    <span className="todo__content--headerTitle"> {moment(valueDateRange[0]).format('Do MMMM YYYY')} - {moment(valueDateRange[1]).format('Do MMMM YYYY')}  </span>
                                    <span className="todo__content--headerSubtitle">{todosFiltered.length > 0 ? "Vos taches" : "Vous n'avez pas encore de taches "}</span>
                                </>
                            )
                        }


                    </div>
                    <div className="todo__content--headerRight">
                        <button className="button icon--only" onClick={() => setShowDRP(!showDRP)}>
                            <i className="uil uil-calendar-alt "></i>
                        </button>
                    </div>
                </div>
                <div className={`todo__datePicker ${showDRP ? "content-show " : "content-hide"}  `}    >
                    <LocalizationProvider dateAdapter={MomentAdapter} locale="fr">
                        <DateRangePicker
                            toolbarTitle="Date"
                            cancelText="Annuler"
                            okText="OK"
                            startText="Debut"
                            endText="Fin"
                            value={valueDateRange}
                            onChange={(newValue) => {
                                setValueDateRange(newValue);
                                //getTodosStatus(taskStatus, newValue[0], newValue[1])
                                filteredTodo(taskStatus, newValue[0], newValue[1], searchTerm);
                            }}
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField {...startProps} helperText="jj/mm/aaaa" />
                                    <DateRangeDelimiter> au </DateRangeDelimiter>
                                    <TextField {...endProps} helperText="jj/mm/aaaa" />
                                </>
                            )}
                        />

                    </LocalizationProvider>
                </div>
            </div>

            <FormControl className="">
                <InputLabel id="demo-simple-select-label">Filtre</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={taskStatus}
                    onChange={handleChange}
                >
                    {tasksStatus.map(t => (
                        <MenuItem value={t.id}>{t.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                id="input-search-with-icon-textfield"
                placeholder="Rechercher..."
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                    <SearchIcon />
                    </InputAdornment>
                ),
                }}
                variant="standard"
                onChange={searchItemHandler}
            />

            <div className="todo__total-minutes">
                Total: {countTotalMinutes} minutes
            </div>

            <div className="todo__list">
                {todosFiltered && todosFiltered
                    .sort((a, b) => a.priority > b.priority ? 1 : -1)
                    .map((todo) => {
                        return (
                            <>
                                {!todo.weeklyGoal == "" ? (
                                    <>
                                        {weeklyGoals && weeklyGoals.map(weeklyGoal => {
                                            return (
                                                <>
                                                    {weeklyGoal._id == todo.weeklyGoal ?
                                                        <TodoScreen
                                                            todo={todo}
                                                            key={todo._id}
                                                            setTodo={setTodo}
                                                            weeklyGoal={weeklyGoal}
                                                            setOpenBackdrop={setOpenBackdrop}
                                                            setIsAddComponent={setIsAddComponent}
                                                            setOpenConfirmation={setOpenConfirmation}
                                                            setIdToDelete={setIdToDelete}
                                                        />
                                                        :
                                                        <>
                                                        </>
                                                    }
                                                </>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <TodoScreen
                                            todo={todo}
                                            key={todo._id}
                                            setTodo={setTodo}
                                            weeklyGoal=""
                                            setOpenBackdrop={setOpenBackdrop}
                                            setIsAddComponent={setIsAddComponent}
                                            setOpenConfirmation={setOpenConfirmation}
                                            setIdToDelete={setIdToDelete}
                                        />
                                    </>
                                )}
                            </>);
                    })}
            </div>
        </div >
    )
}

export default connect(null, { getTodos, updateAllTodos, getTodosStatus, getTodosStatusToday, getWeeklyGoals })(ListTodosScreen);