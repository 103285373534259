import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Redirect, useHistory } from 'react-router-dom';

//tutopage
import Welcome from '../screens/welcome/Welcome';

import { connect } from 'react-redux';

// Get all delete actions
import { deleteRoutine } from '../../actions/routineActions';
import { deleteTodo } from '../../actions/todoActions';
import { deleteYearlyGoal } from '../../actions/yearlyGoalActions'
import { deleteTrimesterGoal } from '../../actions/trimesterGoalActions'
import { deleteWeeklyGoal } from '../../actions/weeklyGoalActions'
import { restartChallenge } from '../../actions/authActions'

function ConfirmationModal({
    component, confirmationTitle,
    confirmationText, openConfirmation,
    setOpenConfirmation,
    idToDelete, deleteTodo, deleteYearlyGoal, deleteTrimesterGoal, deleteWeeklyGoal, deleteRoutine,
    setOpenBackdrop,
    restartChallenge
}) {
    let history = useHistory();

    const theme = useTheme();

    const handleClickOpen = () => {
        setOpenConfirmation(true);
    };

    const handleClose = () => {
        setOpenConfirmation(false);
    };

    const handleConfirm = () => {
        switch (component) {
            case "Routine":
                deleteRoutine(idToDelete);
                handleClose();
                setOpenBackdrop(true);
                break;
            case "Todo":
                deleteTodo(idToDelete);
                handleClose();
                setOpenBackdrop(true);
                break;
            case "Yearly Goal":
                deleteYearlyGoal(idToDelete, 1);
                handleClose();
                setOpenBackdrop(true);
                break;
            case "Trimester Goal":
                deleteTrimesterGoal(idToDelete, 2);
                handleClose();
                setOpenBackdrop(true);
                break;
            case "Weekly Goal":
                deleteWeeklyGoal(idToDelete, 3);
                handleClose();
                setOpenBackdrop(true);
                break;
            case "Restart":
                restartChallenge(history);
                handleClose();
                setOpenBackdrop(true);
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <Dialog
                open={openConfirmation}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">{confirmationTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {confirmationText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleConfirm} color="primary">
                        Supprimer
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default connect(null, { deleteTodo, deleteYearlyGoal, deleteTrimesterGoal, deleteWeeklyGoal, deleteRoutine, restartChallenge })(ConfirmationModal);
