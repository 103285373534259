import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//mobile header
import MobileHeader from "../Header/HeaderMobile";
import MyAddbutton from "../../buttons/MyAddButton/MyAddbutton";
import Mymodal from "../../modal/Mymodal";
import AddRecompenseScreen from "./AddRecompenseScreen";
import ListRecompenseScrenn from "./ListRecompenseScrenn";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    minWidth: 100,
    background: "var(----first-color)",
  },
});

function RecompenseScreen({
  propsAdd = "rewardADD",
  isAddComponent,
  setIsAddComponent,
}) {
  const [reward, setReward] = useState({
    name: "",
    point: 0,
  });

  // for style component
  const classes = useStyles();

  return (
    <div className="recompense__section section">
      <MobileHeader
        title="Récompenses"
        right={
          <div>
            {/* {
              <IconButton>
                <EditIcon className="button__right" />
              </IconButton>
            } */}
          </div>
        }
      />

      <MyAddbutton propsAdd={propsAdd} />
      <div className="recompense__container container grid">
        <Mymodal
          className="reward-modal"
          modalTitle="Récompense"
          setIsAddComponent={setIsAddComponent}
          modalContent={
            <>
              <AddRecompenseScreen
                reward={reward}
                setReward={setReward}
                isAddComponent={isAddComponent}
              />
            </>
          }
        />

        <Card className="accordion">
          <CardContent>
            <h4>Point total</h4>
          </CardContent>
        </Card>
        <ListRecompenseScrenn
          reward={reward}
          setReward={setReward}
          setIsAddComponent={setIsAddComponent}
        />
      </div>
    </div>
  );
}

export default connect(null)(RecompenseScreen);
