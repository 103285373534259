import React, { useState, useEffect, Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, ButtonGroup, Button, TextField } from "@material-ui/core";
import { Create, Delete, CheckCircle, Send, StopSharp } from "@material-ui/icons";
import CancelIcon from '@material-ui/icons/Warning';
import moment from "moment";
import { connect } from 'react-redux';

import "../CSS/TrimesterGoalScreen.css";

import { updateTrimesterGoal, deleteTrimesterGoal } from '../../../../../actions/trimesterGoalActions'


//card
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CachedIcon from '@material-ui/icons/Cached';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';

import Tooltip from '@material-ui/core/Tooltip';


import $ from 'jquery';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const TrimesterGoalScreen = ({
    trimesterGoal, setTrimesterGoal, yearlyGoal,
    updateTrimesterGoal, deleteTrimesterGoal,
    setIsUpdate, setOpenBackdrop, setIsAddComponent,
    setOpenConfirmation, setIdToDelete
}) => {
    const dispatch = useDispatch();
    const yearlyGoals = useSelector(state => state.yearlyGoal)
    const trimesterGoals = useSelector((state) => state.trimesterGoal);
    const [realisedTrimesterGoal, setRealisedTrimesterGoal] = useState({
        ...trimesterGoal,
        realNumberedGoal: trimesterGoal.realNumberedGoal
    });
    const [noteTrimesterGoal, setNoteTrimesterGoal] = useState({
        ...trimesterGoal,
        note: trimesterGoal.note
    })

    const updateClickHandler = (popupState) => {
        // As we are using the same form as "ADD" method, 
        // setting setTrimesterGoal here will auto-prefill the different inputs
        // So that user can change only what he wants
        setTrimesterGoal({ ...trimesterGoal });

        popupState.close();
        $(".trimesterADD").trigger("click");
        setIsAddComponent(false)
    }

    const realisedTrimesterGoalHandler = () => {
        if (trimesterGoal._id) {
            setOpenBackdrop(true);
            const id = trimesterGoal._id

            const updatedRealisedTrimesterGoal = {
                ...trimesterGoal,
                realNumberedGoal: realisedTrimesterGoal.realNumberedGoal,
            }

            updateTrimesterGoal(updatedRealisedTrimesterGoal, id, 2);
        }
    }

    const noteTrimesterGoalHandler = () => {
        if (trimesterGoal._id) {
            setOpenBackdrop(true);
            const id = trimesterGoal._id

            setTrimesterGoal({
                ...trimesterGoal,
                note: noteTrimesterGoal.note
            })

            const updatedNoteTrimesterGoal = {
                ...trimesterGoal,
                note: noteTrimesterGoal.note,
            }

            updateTrimesterGoal(updatedNoteTrimesterGoal, id, 2);

            setTrimesterGoal({
                ...trimesterGoal,
                note: ""
            })
        }
    }

    const deleteHandler = (id) => {
        setOpenBackdrop(true);
        deleteTrimesterGoal(id, 2);
    }

    const stopHandler = () => {
        if (trimesterGoal._id) {
            setOpenBackdrop(true);
            const id = trimesterGoal._id

            setTrimesterGoal({
                ...trimesterGoal,
                active: false,
                note: ""
            })

            const updatedActiveTrimesterGoal = {
                ...trimesterGoal,
                active: false,
                note: "",
            }

            updateTrimesterGoal(updatedActiveTrimesterGoal, id, 2);

            setTrimesterGoal({
                ...trimesterGoal,
                active: true,
                note: ""
            })
        }
    }

    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div>

            <Card className="my__oneCard" style={{ backgroundColor: trimesterGoal.color }}>
                <CardHeader
                    className="cardHeader"
                    avatar={
                        trimesterGoal.isCompleted ? (
                            <CheckCircle className="isCompleted" />
                        ) : !trimesterGoal.active ? (
                            <CancelIcon className="notActive" />
                        ) : !trimesterGoal.deleteBtn ? (
                            <CachedIcon className="isRunning" />
                        ) : (
                            <RadioButtonUncheckedIcon className="action inside--card-icon" />
                        )
                    }
                    action={
                        <>
                            <PopupState variant="popover" popupId="demo-popup-popover" >
                                {(popupState) => (
                                    <div >
                                        <MoreVertIcon {...bindTrigger(popupState)} className="inside--card-icon" />
                                        <Popover className="popover--container"
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Box p={1}>
                                                <div>
                                                    {trimesterGoal.isCompleted ? (
                                                        <Button>
                                                            <CheckCircle className="isCompleted" />
                                                        </Button>
                                                    ) : !trimesterGoal.active ? (
                                                        <CancelIcon className="notActive" />
                                                    ) : trimesterGoal.deleteBtn ? (
                                                        <div>
                                                            <Button onClick={() => { updateClickHandler(popupState); setIsUpdate(false) }}>
                                                                <Create color="primary" />
                                                            </Button>
                                                            <Button onClick={() => { setIdToDelete(trimesterGoal._id); setOpenConfirmation(true) }}>
                                                                <Delete className="isActive" />
                                                            </Button>
                                                            {/* <Button onClick={() => deleteHandler(trimesterGoal._id)}>
                                                                <Delete className="isActive" />
                                                            </Button> */}
                                                        </div>
                                                    ) : (
                                                        <div>

                                                            <Button onClick={() => { updateClickHandler(popupState); setIsUpdate(true) }}>
                                                                <Create color="primary" />
                                                            </Button>
                                                            <Button onClick={() => stopHandler()}>
                                                                <StopSharp className="isActive" />
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>

                                            </Box>
                                        </Popover>
                                    </div>
                                )}
                            </PopupState>
                        </>
                    }
                    title={
                        <div>
                            <h3 className="qualification__title">  {trimesterGoal.estimatedNumberedGoal} {" "} {trimesterGoal.name}</h3>
                            {trimesterGoal.comment ? (
                                <div className="">
                                    {trimesterGoal.comment}
                                </div>
                            ) : (
                                <>
                                </>
                            )}
                        </div>
                    }
                    subheader={
                        <div>
                            <div className="goal__card-content ">
                                <div>
                                    <span className="inside--card">
                                        <i className="uil uil-link"></i>
                                        Objectif annuel:  {yearlyGoal.estimatedNumberedGoal} {yearlyGoal.name}
                                    </span>
                                    <span className="inside--card">
                                        <i className="uil uil-process"></i>
                                        Achevé: {trimesterGoal.percentageAchieved}%
                                    </span>

                                    <span className="inside--card">
                                        <i className="uil uil-calender"></i>
                                        Ajouté: {moment(trimesterGoal.createdAt).fromNow()}
                                    </span>
                                    {trimesterGoal.note ?
                                        (<span className="inside--card inside__card--note">
                                            <i className="uil uil-notes "></i>
                                            {trimesterGoal.note}
                                        </span>) : (<></>)
                                    }
                                </div>

                                <div className="">
                                    <IconButton
                                        className={clsx(classes.expand, {
                                            [classes.expandOpen]: expanded,
                                        })}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                    >
                                        {trimesterGoal.isCompleted ? (
                                            <div></div>
                                        ) : !trimesterGoal.active ? (
                                            <NoteAddIcon className="isCompleted" />
                                        ) : trimesterGoal.outOfTime ? (
                                            <NoteAddIcon className="isCompleted" />
                                        ) : trimesterGoal.deleteBtn ? (
                                            <AssignmentTurnedInIcon className="isCompleted" />
                                        ) : (
                                            <>
                                                <AssignmentTurnedInIcon className="isCompleted" />
                                            </>
                                        )}
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Collapse in={expanded} timeout="auto" unmountOnExit >
                    <CardContent className="cardContent">
                        <div className="goal__card-input">
                            {trimesterGoal.active ? (
                                <>
                                    {/*{!trimesterGoal.outOfTime ? (
                                        <>*/}
                                            {!trimesterGoal.isCompleted ? (
                                                <>
                                                    <div className="inside--card-right">
                                                        <TextField
                                                            id="realisedNumber"
                                                            label="Nombre"
                                                            variant="outlined"
                                                            type="number"
                                                            size="small"
                                                            style={{ width: '100%' }}
                                                            step="1"
                                                            value={realisedTrimesterGoal.realNumberedGoal}
                                                            onChange={(e) => setRealisedTrimesterGoal({ ...realisedTrimesterGoal, realNumberedGoal: Math.round(e.target.value) })}
                                                            required
                                                        />

                                                        <Button onClick={() => realisedTrimesterGoalHandler()}>
                                                            <Send color="primary" />
                                                        </Button>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="inside--card-right">
                                                        <TextField
                                                            id="note"
                                                            label="Pourquoi avez-vous stoppé cet objectif?"
                                                            variant="outlined"
                                                            type="text"
                                                            size="small"
                                                            style={{ width: '100%' }}
                                                            value={noteTrimesterGoal.note}
                                                            onChange={(e) => setNoteTrimesterGoal({ ...noteTrimesterGoal, note: e.target.value })}
                                                            required
                                                        />
                                                        <Button onClick={() => noteTrimesterGoalHandler()}>
                                                            <Send color="primary" />
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        {/*</>
                                    }) : (
                                        <>
                                            <div className="inside--card-right">
                                                <TextField
                                                    id="note"
                                                    label="Pourquoi avez-vous stoppé cet objectif?"
                                                    variant="outlined"
                                                    type="text"
                                                    size="small"
                                                    style={{ width: '100%' }}
                                                    value={noteTrimesterGoal.note}
                                                    onChange={(e) => setNoteTrimesterGoal({ ...noteTrimesterGoal, note: e.target.value })}
                                                    required
                                                />
                                                <Button onClick={() => noteTrimesterGoalHandler()}>
                                                    <Send color="primary" />
                                                </Button>
                                            </div>
                                        </>
                                    )}*/}
                                </>
                            ) : (
                                <>
                                    <div className="inside--card-right">
                                        <TextField
                                            id="note"
                                            label="Pourquoi avez-vous stoppé cet objectif?"
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            style={{ width: '100%' }}
                                            value={noteTrimesterGoal.note}
                                            onChange={(e) => setNoteTrimesterGoal({ ...noteTrimesterGoal, note: e.target.value })}
                                            required
                                        />
                                        <Button onClick={() => noteTrimesterGoalHandler()}>
                                            <Send color="primary" />
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </CardContent>
                </Collapse>
                <Box p={1}> <div className="inside--card-date">
                    Dernière modification : {moment(trimesterGoal.updatedAt).fromNow()}
                </div>
                </Box>
            </Card>
        </div>
    )
}

export default connect(null, { updateTrimesterGoal, deleteTrimesterGoal })(TrimesterGoalScreen);