import { toast } from 'react-toastify';

const todoReducer = (state = [], action) => {
    switch (action.type) {
        case "GET_TODOS":
            return action.todos.data;
        case "GET_UPDATED_TODOS":
            return action.todos.data;
        case "GET_TODOS_STATUS":
            return action.todos.data;
        /*case "GET_TODOS_STATUS_TODAY":
            return action.todos.data;*/
        case "ADD_TODO":
            toast.success("New task successfully added...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return action.todo.data;
        //return [action.todo.data, ...state];
        case "UPDATE_TODO":
            toast.success("Task successfully updated...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.map((todo) => todo._id === action.todo.data._id ? action.todo.data : todo
            )
        case "DELETE_TODO":
            toast.success("Task successfully deleted...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.filter((todo) => todo._id !== action.id
            )
        case "START_TODO":
            toast.success("Task successfully started...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.map((todo) =>
                todo._id === action.todo.data._id ? action.todo.data : todo
            )
        case "STOP_TODO":
            toast.success("Task successfully ended...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.map((todo) =>
                todo._id === action.todo.data._id ? action.todo.data : todo
            )
        case "PAUSE_TODO":
            toast.success("Task successfully paused...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.map((todo) =>
                todo._id === action.todo.data._id ? action.todo.data : todo
            )
        default:
            return state
    }
}

export default todoReducer;