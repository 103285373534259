import React from "react";
import MobileHeader from "../Header/HeaderMobile";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import imgNet from "./Network-Profile.png";
import "./Profile.css";
import { Link } from "react-router-dom";

function Profile() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: "auto",
        width: "100%",
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
  }));
  const classes = useStyles();
  return (
    <div className="profile__section section">
      <div className="profile__back"></div>
      <MobileHeader className="no__bg" title="" right={<div></div>} />
      <div className="profile__container container grid">
        <div className="profile__avatar">
          <div className={classes.root}>
            <Avatar alt="Remy Sharp" src={imgNet} className={classes.large} />
          </div>

          <div className="profile__text">
            <h2 className="profile__name">Sitraka Andria</h2>
          </div>
        </div>

        <div className="profile__info ">
          <div className="contact__information">
            <i className="uil uil-envelope contact__icon"></i>
            <div>
              <h3 className="contact__title">Email</h3>
              <span className="contact__subtitle">test@test.com</span>
            </div>
          </div>
        </div>

        <div>
          <Link to="/forgotpassword" className="button button--flex">
            Forgot Password
            <i className="uil uil-history button__icon"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Profile;
