import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Select, MenuItem } from '@material-ui/core';
import { Send, ExpandMore } from '@material-ui/icons';
import { useTranslation } from "react-i18next";
import { useMinimalSelectStyles } from '@mui-treasury/styles/select/minimal';
import "../CSS/AddRoutineScreen.css";
import "react-datepicker/dist/react-datepicker.css";
import { addRoutine, updateRoutine } from '../../../../actions/routineActions';

import { connect } from 'react-redux';
import moment from 'moment';

import { numberStepIntro } from '../../../../actions/authActions'
import MomentAdapter from "@material-ui/pickers/adapter/moment";

const AddRoutineScreen = ({ routine, setRoutine, addRoutine, updateRoutine, locationComponent, activeStep, setActiveStep, numberStepIntro, openBackDrop, setOpenBackdrop, isAddComponent }) => {
    const { t, i18n } = useTranslation("");
    const auth = useSelector(state => state.auth.user);
    const routines = useSelector(state => state.routines)

    const minimalSelectClasses = useMinimalSelectStyles();
    const iconComponent = (props) => {
        return (
            <ExpandMore className={props.className + " " + minimalSelectClasses.icon} />
        )
    };
    const menuProps = {
        classes: {
            paper: minimalSelectClasses.paper,
            list: minimalSelectClasses.list
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };

    const submitHandler = (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        // Check if it's an update or a add (if _id exists, then it's an update)
        if (routine._id) {
            const id = routine._id

            const updatedRoutine = {
                name: routine.name,
                isCompleted: routine.isCompleted,
                comment: routine.comment,
            }

            updateRoutine(updatedRoutine, id);

            setRoutine({
                name: "",
                isCompleted: false,
                comment: ""
            });
        } else {
            const newRoutine = {
                name: routine.name,
                isCompleted: routine.isCompleted,
                comment: routine.comment,
            }

            // Dispatch the routine using the action created in store
            addRoutine(newRoutine);
        }

        setTimeout(() => {
            if (locationComponent == "Intro") {
                setActiveStep((activeStep) => activeStep + 2);
                const updatedStep = {
                    _id: auth._id,
                    stepIntro: 7,
                };
                numberStepIntro(updatedStep);
            } else {
                const oneModaltodo = document.querySelector(".modal__views");
                oneModaltodo.classList.remove("active-modal");
            }
        }, 2000);
    }

    useEffect(() => {
        if (isAddComponent === true) {
            setRoutine({
                name: "",
                isCompleted: false,
                comment: ""
            });
        }
    }, [isAddComponent, routines]);

    return (
        <div>
            <form autoComplete="off" className="todo__add-Form" onSubmit={submitHandler}>
                <div className="todo__input">
                    <TextField
                        id="routineName"
                        label="Routine à faire"
                        variant="outlined"
                        autoFocus
                        fullWidth
                        value={routine.name}
                        onChange={(e) => setRoutine({ ...routine, name: e.target.value })}
                        required
                    />

                    <TextField
                        id="routineComment"
                        label="Commentaire"
                        multiline
                        rowsMax="3"
                        value={routine.comment}
                        onChange={(e) => setRoutine({ ...routine, comment: e.target.value })}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                </div>

                <Button variant="contained" color="primary" className="submitButton" type="submit">
                    <Send />
                </Button>
            </form>
        </div>
    )
}

export default connect(null, { addRoutine, updateRoutine, numberStepIntro })(AddRoutineScreen);