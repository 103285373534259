import axios from 'axios';
import { toast } from 'react-toastify';
import { setHeaders } from '../api/headers';

// Get all todos
export const getTodos = () => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get('/api/todo/alltodos', setHeaders(token))
        .then(todos => {
            //console.log("TodoActions: ", todos);
            dispatch({
                type: "GET_TODOS",
                todos
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Get all updated todos
export const updateAllTodos = () => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get('/api/todo/allupdatedtodos', setHeaders(token))
        .then(todos => {
            //console.log("TodoActions: ", todos);
            dispatch({
                type: "GET_UPDATED_TODOS",
                todos
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Get all todos with params
export const getTodosStatus = (status, startDate, endDate) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get(`/api/todo/alltodosstatus/${status}/${startDate}/${endDate}`, setHeaders(token))
        .then(todos => {
            dispatch({
                type: "GET_TODOS_STATUS",
                todos
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}

// Get all todos with params
/*export const getTodosStatusToday = (status) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get(`/api/todo/alltodosstatustoday/${status}`, setHeaders(token))
        .then(todos => {
            dispatch({
                type: "GET_TODOS_STATUS_TODAY",
                todos
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}*/


// Create a new todo
export const addTodo = (todo, type, recuEnd) => (dispatch, getState) => {
    const uid = getState().auth.user._id;
    const token = getState().auth.user.token

    console.log("TODO: ", todo);

    axios.post(`/api/todo/createtodo/${type}/${recuEnd}`, { ...todo, uid }, setHeaders(token))
        .then(todo => {
            dispatch({
                type: "ADD_TODO",
                todo
            })
        })
        .catch(error => {
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Update a todo
export const updateTodo = (updateTodo, id) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.put(`/api/todo/updatetodo/${id}`, updateTodo, setHeaders(token))
        .then(todo => {
            //console.log("TodoActions: ", todo);
            dispatch({
                type: "UPDATE_TODO",
                todo
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Delete a todo
export const deleteTodo = (id) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.delete(`/api/todo/removetodo/${id}`, setHeaders(token))
        .then(() => {
            //console.log("TodoActions: ", todo);
            dispatch({
                type: "DELETE_TODO",
                id
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Update start time of a task
export const startTodo = (id) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.put(`/api/todo/starttodo/${id}`, {}, setHeaders(token))
        .then(todo => {
            //console.log("TodoActions: ", todo);
            dispatch({
                type: "START_TODO",
                todo
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Update stop time of a task
export const stopTodo = (id, updatedTodo) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.put(`/api/todo/endtodo/${id}`, updatedTodo, setHeaders(token))
        .then(todo => {
            //console.log("TodoActions: ", todo);
            dispatch({
                type: "STOP_TODO",
                todo
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Update pause time of a task
export const pauseTodo = (id, updatedTodo) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.put(`/api/todo/pausetodo/${id}`, updatedTodo, setHeaders(token))
        .then(todo => {
            //console.log("TodoActions: ", todo);
            dispatch({
                type: "PAUSE_TODO",
                todo
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


