import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Paper, Button, TextField } from "@material-ui/core";
import { Send } from '@material-ui/icons';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//inputs
import AddYG from "../Goal/Yearly/JS/AddYearlyGoalScreen";
import AddTG from "../Goal/Trimester/JS/AddTrimesterGoalScreen";
import AddWG from "../Goal/Weekly/JS/AddWeeklyGoalScreen";
import AddTODO from "../Todo/JS/AddTodoScreen";
//stepper
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";

import "./Welcome.css";

// Date picker
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

import { startChallenge, numberStepIntro } from "../../../actions/authActions";
import { addYearlyGoal } from '../../../actions/yearlyGoalActions'

//icons
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

//translation
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
},
}));

function getSteps() {
  return ["Intro", "Start", "Yearly", "Trimester", "Weekly", "To do"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Introduction";
    case 1:
      return "Start ";
    case 2:
      return "Yearly";
    case 3:
      return "Trimester";
    case 4:
      return "Weekly";
    case 5:
      return "Todo";
    default:
      return "Unknown stepIndex";
  }
}

function Welcome({ startChallenge, numberStepIntro, addYearlyGoal, setIsAddComponent }) {
  const [openBackDrop, setOpenBackdrop] = useState(false);

  const { t, i18n } = useTranslation("");

  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
  };

  const auth = useSelector((state) => state.auth.user);
  const [startChallengeDate, setStartChallengeDate] = useState(new Date());
  const yg = useSelector(state => state.yearlyGoal);
  const tg = useSelector(state => state.trimesterGoal);
  const wg = useSelector(state => state.weeklyGoal);
  const todos = useSelector(state => state.todos);
  const stepIntro = useSelector((state) => state.auth.user.stepIntro);
  //yg
  const [yearlyGoal, setYearlyGoal] = useState({
    type: 1,
    estimatedNumberedGoal: "",
    name: "",
    realNumberedGoal: 0,
    note: "",
    percentageAchieved: 0,
    active: true,
    deleteBtn: true,
  });

  //tg
  const [trimesterGoal, setTrimesterGoal] = useState({
    type: 2,
    estimatedNumberedGoal: "",
    name: "",
    note: "",
    percentageAchieved: 0,
    active: true,
    yearlyGoalID: "",
    trimester: "",
    deleteBtn: true,
  });

  //wg

  const [weeklyGoal, setWeeklyGoal] = useState({
    type: 3,
    estimatedNumberedGoal: "",
    name: "",
    note: "",
    percentageAchieved: 0,
    active: true,
    trimesterGoalID: "",
    week: "",
    deleteBtn: true,
  });

  //todo
  const [todo, setTodo] = useState({
    name: "",
    estimatedDuration: null,
    isCompleted: false,
    todoDate: new Date(),
  });

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(stepIntro || 0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNextStartChallenge = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    const updatedStep = {
      _id: auth._id,
      stepIntro: 2,
    };

    numberStepIntro(updatedStep);
    startChallenge(startChallengeDate, auth._id);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSkip = () => {
    setActiveStep(7);
    const updatedStep = {
      _id: auth._id,
      stepIntro: 7,
    };
    numberStepIntro(updatedStep);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    setYearlyGoal({
      ...yearlyGoal,
      name: yearlyGoal.name,
      estimatedNumberedGoal: yearlyGoal.estimatedNumberedGoal,
      realNumberedGoal: yearlyGoal.realNumberedGoal,
      note: "",
      percentageAchieved: yearlyGoal.percentageAchieved,
  })
    addYearlyGoal(yearlyGoal, yearlyGoal.type);
  }

  useEffect(() => {
    setOpenBackdrop(false);
  }, [yg])

  useEffect(() => {
    setOpenBackdrop(false);
  }, [tg])

  useEffect(() => {
    setOpenBackdrop(false);
  }, [wg])

  useEffect(() => {
    setOpenBackdrop(false);
  }, [todos])

  return (
    <div className="welcome">

      <Backdrop className={classes.backdrop} open={openBackDrop} >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
            <h2 className="section__title">Vous êtes prêt !</h2>
            <span className="section__subtitle">
              <p>
                Cet outil est le fruit d’année d’expérience, de recherche et de test.
              </p><br></br>
              <p>
                Vous allez dès maintenant relever un des plus grand défi de notre époque:
              </p>
              <p>
                Être efficace au lieu d’être occupé !!!
              </p><br></br>
              <p>
                Notre équipe vous souhaite un beau parcours
              </p>
            </span>
            <div className="btn--intro">
              <Link to="/">
                <Button  className={classes.backButton}>
                  Aller sur mon tableau de bord
                </Button>
              </Link>
            </div>
          </div>
          ) : (
            <div className="welcome__slides">
              <div className="section__hello">
                <div className="container grid">
                  {activeStep == 0 ? (
                    /* intro */
                    <div>
                      <h2 className="section__title">
                        Efficacité 360&#8482;
                      </h2>
                      <span className="section__subtitle">
                        <p>
                          La plateforme qui va vous permettre de retrouver de l’efficacité en toute sérénité.
                        </p>
                      </span>
                      <div className="btn--intro">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                        >
                          {t("buttons.next")}
                        </Button>
                      </div>
                    </div>
                  ) : activeStep == 1 ? (
                    /* Start */
                    <div>
                      <h2 className="section__title">
                        {t("welcome.startChallenge")}
                      </h2>
                      <span className="section__subtitle">
                        {t("welcome.infoStartChallenge")}
                      </span>
                      <div className="start__input">
                        <LocalizationProvider dateAdapter={MomentAdapter}>
                          <DatePicker
                            minDate={new Date()}
                            label="Choisissez une date"
                            value={startChallengeDate}
                            onChange={(newValue) =>
                              setStartChallengeDate(newValue)
                            }
                            renderInput={(props) => (
                              <TextField {...props} helperText="jj/mm/aaaa" />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="btn--intro">
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          {t("buttons.prev")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNextStartChallenge}
                        >
                          {t("buttons.next")}
                        </Button>
                      </div>
                    </div>
                  ) : activeStep == 2 ? (
                    /* YG */
                    <div>
                      <h2 className="section__title">
                        {t("labels.yearlyGoal")}
                      </h2>
                      <span className="section__subtitle">
                        {t("welcome.infoYearlyGoal")}
                      </span>
                      <AddYG
                        yearlyGoal={yearlyGoal}
                        setYearlyGoal={setYearlyGoal}
                        isUptate={false}
                        locationComponent="Intro"
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        openBackDrop={openBackDrop}
                        setOpenBackdrop={setOpenBackdrop}
                        setIsAddComponent={setIsAddComponent}
                        isAddComponent={true}
                      />
                      <div className="btn--intro">
                        {/* <Button
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          {t("buttons.prev")}
                        </Button> */}
                        <Button
                          className={classes.backButton}
                          onClick={handleSkip}
                        >
                          {t("buttons.skip")}
                        </Button>
                      </div>
                    </div>
                  ) : activeStep == 3 ? (
                    /* TG */
                    <div>
                      <h2 className="section__title">
                        {t("labels.trimesterGoal")}
                      </h2>
                      <span className="section__subtitle">
                        {t("welcome.infoTrimestrerGoal")}
                      </span>
                      <AddTG
                        trimesterGoal={trimesterGoal}
                        setTrimesterGoal={setTrimesterGoal}
                        isUptate={false}
                        locationComponent="Intro"
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        openBackDrop={openBackDrop}
                        setOpenBackdrop={setOpenBackdrop}
                        setIsAddComponent={setIsAddComponent}
                        isAddComponent={true}
                      />
                      <div className="btn--intro">
                        {/* <Button
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          {t("buttons.prev")}
                        </Button> */}
                        <Button
                          className={classes.backButton}
                          onClick={handleSkip}
                        >
                          {t("buttons.skip")}
                        </Button>
                      </div>
                    </div>
                  ) : activeStep == 4 ? (
                    /* WG */
                    <div>
                      <h2 className="section__title">
                        {t("labels.weeklyGoal")}
                      </h2>
                      <span className="section__subtitle">
                        {t("welcome.infoWeeklyGoal")}
                      </span>
                      <AddWG
                        weeklyGoal={weeklyGoal}
                        setWeeklyGoal={setWeeklyGoal}
                        isUptate={false}
                        locationComponent="Intro"
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        openBackDrop={openBackDrop}
                        setOpenBackdrop={setOpenBackdrop}
                        setIsAddComponent={setIsAddComponent}
                        isAddComponent={true}
                      />
                      <div className="btn--intro">
                        {/* <Button
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          {t("buttons.prev")}
                        </Button> */}
                        <Button
                          className={classes.backButton}
                          onClick={handleSkip}
                        >
                          {t("buttons.skip")}
                        </Button>
                      </div>
                    </div>
                  ) : activeStep == 5 ? (
                    /* TODO */
                    <div>
                      <h2 className="section__title">{t("labels.todo")}</h2>
                      <span className="section__subtitle">
                        {t("welcome.infoTodo")}
                      </span>
                      <AddTODO
                        todo={todo}
                        setTodo={setTodo}
                        isUptate={false}
                        locationComponent="Intro"
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        openBackDrop={openBackDrop}
                        setOpenBackdrop={setOpenBackdrop}
                        setIsAddComponent={setIsAddComponent}
                      />
                      <div className="btn--intro">
                        {/* <Button
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          {t("buttons.prev")}
                        </Button> */}
                        <Button
                          className={classes.backButton}
                          onClick={handleSkip}
                        >
                          {t("buttons.skip")}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h2 className="section__title">Vous êtes prêt !</h2>
                      <span className="section__subtitle">
                        <p>
                          Cet outil est le fruit d’année d’expérience, de recherche et de test.
                        </p><br></br>
                        <p>
                          Vous allez dès maintenant relever un des plus grand défi de notre époque:
                        </p>
                        <p>
                          Être efficace au lieu d’être occupé !!!
                        </p><br></br>
                        <p>
                          Notre équipe vous souhaite un beau parcours
                        </p>
                      </span>
                      <div className="btn--intro">
                        <Link to="/">
                          <Button  className={classes.backButton}>
                            Aller sur mon tableau de bord
                          </Button>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(null, { startChallenge, numberStepIntro, addYearlyGoal })(Welcome);
