import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { serviceWorkerDev } from './serviceWorkerDev';
import { store, persistor } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

import './i18next'

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Suspense fallback="loading">
                    <App />
                </Suspense>
            </PersistGate>
        </Provider>
    </React.StrictMode >,
    document.getElementById('root')
);

serviceWorkerDev();
