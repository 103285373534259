import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { connect } from 'react-redux';

import { logout } from '../../../actions/authActions';


//mobile header
import MobileHeader from '../Header/HeaderMobile'
import IconButton from '@material-ui/core/IconButton';

//tabs
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

//goalsScreen
import YG from './Yearly/JS/YearlyGoalsScreen'
import TG from './Trimester/JS/TrimesterGoalsScreen'
import WG from './Weekly/JS/TabScreen'

import NotStartedYet from "../NotStartedYet";

//backdrop
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

//tab panel
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


const GoalScreen = ({ history, logout, isAddComponent, setIsAddComponent }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const auth = useSelector(state => state.auth.user)

    const logintHandler = () => {
        history.push("/login");
    }

    const [valueTabs, setValueTabs] = useState(parseInt(localStorage.getItem('activeTabsGoals')) || 0);

    const handleChangeTabs = (event, newValue) => {
        setValueTabs(newValue);
        localStorage.setItem('activeTabsGoals', newValue);
    };

    //for bakcdrop
    const classes = useStyles();
    const [openBackDrop, setOpenBackdrop] = useState(false);

    const today = moment(new Date()).format("DD/MM/YYYY");
    const startChallengeUser = moment(auth.startDayChallenge, "DD/MM/YYYY").toDate();
    const startChallengeUserMoment = moment(startChallengeUser).format("DD/MM/YYYY");

    return error ? (
        <div>
            <span className="error-message">{error}</span>
            <button onClick={logintHandler}>Login</button>
        </div>
    ) : (
        <div className="goal__section section">

            <Backdrop className={classes.backdrop} open={openBackDrop} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <MobileHeader title="Objectifs"
                right={
                    <div>
                        {/* <Link to="/my-profile">
                            <IconButton>
                                <i className="uil uil-user-circle button__right"></i>
                            </IconButton>
                        </Link> */}
                    </div>}
            />

            {/* Check if challenge has already started or not */}
            {moment(startChallengeUserMoment) > moment(today) ? (
                <>
                    <NotStartedYet />
                </>
            ) : (
                <>
                    <div className="goal__container container grid">
                        <Tabs className="my__tabs"
                            value={valueTabs}
                            onChange={handleChangeTabs}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab className="my__tab-title" label="Annuels" {...a11yProps(0)} />
                            <Tab className="my__tab-title" label="Trimestriels" {...a11yProps(1)} />
                            <Tab className="my__tab-title" label="Hebdomadaires" {...a11yProps(2)} />
                        </Tabs>

                        <TabPanel value={valueTabs} index={0}>
                            <YG setOpenBackdrop={setOpenBackdrop} isAddComponent={isAddComponent} setIsAddComponent={setIsAddComponent} />
                        </TabPanel>
                        <TabPanel value={valueTabs} index={1}>
                            <TG setOpenBackdrop={setOpenBackdrop} isAddComponent={isAddComponent} setIsAddComponent={setIsAddComponent} />
                        </TabPanel>
                        <TabPanel value={valueTabs} index={2}>
                            <WG setOpenBackdrop={setOpenBackdrop} isAddComponent={isAddComponent} setIsAddComponent={setIsAddComponent} />
                        </TabPanel>
                    </div>
                </>
            )}
        </div>
    );
}

export default connect(null, { logout })(GoalScreen);