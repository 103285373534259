import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextField, Button, Select, MenuItem } from "@material-ui/core";
import { Send, ExpandMore } from "@material-ui/icons";

function AddRecompenseScreen({ reward, setReward, isAddComponent }) {
  useEffect(() => {
    if (isAddComponent === true) {
      setReward({ name: "", point: 0 });
    }
  }, [isAddComponent]);

  const submitHandler = () => {};
  return (
    <div>
      <form
        autoComplete="off"
        className="todo__add-Form"
        onSubmit={submitHandler}
      >
        <div className="todo__input">
          <TextField
            id="name"
            label="Nom"
            variant="outlined"
            autoFocus
            fullWidth
            value={reward.name}
            onChange={(e) => setReward({ ...reward, name: e.target.value })}
            required
          />
        </div>
        <div className="todo__input">
          <TextField
            id="price"
            label="Valeur"
            variant="outlined"
            type="number"
            fullWidth
            min="0"
            stpe="1"
            value={reward.point}
            onChange={(e) => setReward({ ...reward, point: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </div>

        <Button
          variant="contained"
          color="primary"
          className="submitButton"
          type="submit"
        >
          <Send />
        </Button>
      </form>
    </div>
  );
}

export default connect(null)(AddRecompenseScreen);
