import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from "react-router-dom";
import { TextField, Button, Select, MenuItem } from '@material-ui/core';
import { useMinimalSelectStyles } from '@mui-treasury/styles/select/minimal';
import { Send, ExpandMore } from '@material-ui/icons';
import moment from 'moment';

import "../CSS/AddTrimesterGoalScreen.css";
import "react-datepicker/dist/react-datepicker.css";

import { connect } from 'react-redux';

import { addTrimesterGoal, updateTrimesterGoal } from '../../../../../actions/trimesterGoalActions'
import { getYearlyGoals } from '../../../../../actions/yearlyGoalActions'
import { numberStepIntro } from '../../../../../actions/authActions'

const AddTrimesterGoalScreen = ({ trimesterGoal, setTrimesterGoal, history, addTrimesterGoal, updateTrimesterGoal, getYearlyGoals, isUptate, setIsUpdate, locationComponent, activeStep, setActiveStep, numberStepIntro, setOpenBackdrop, isAddComponent }) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.user);
    const yearlyGoals = useSelector((state) => state.yearlyGoal);
    const trimesterGoals = useSelector(state => state.trimesterGoal);
    const [trimesterName, setTrimesterName] = useState([
        {
            value: "t1",
            name: `Trimestre 1: ${auth.trimesters.t1.startDay} au ${auth.trimesters.t1.endDay}`,
            endDay: `${auth.trimesters.t1.endDay}`,
        },
        {
            value: "t2",
            name: `Trimestre 2: ${auth.trimesters.t2.startDay} au ${auth.trimesters.t2.endDay}`,
            endDay: `${auth.trimesters.t2.endDay}`
        },
        {
            value: "t3",
            name: `Trimestre 3: ${auth.trimesters.t3.startDay} au ${auth.trimesters.t3.endDay}`,
            endDay: `${auth.trimesters.t3.endDay}`
        },
        {
            value: "t4",
            name: `Trimestre 4: ${auth.trimesters.t4.startDay} au ${auth.trimesters.t4.endDay}`,
            endDay: `${auth.trimesters.t4.endDay}`
        },
    ]);

    const minimalSelectClasses = useMinimalSelectStyles();
    const iconComponent = (props) => {
        return (
            <ExpandMore className={props.className + " " + minimalSelectClasses.icon} />
        )
    };
    const menuProps = {
        classes: {
            paper: minimalSelectClasses.paper,
            list: minimalSelectClasses.list
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };

    useEffect(() => {
        getYearlyGoals();
    }, [])

    const submitHandler = async (e) => {
        // Check if it's an update or a add (if _id exists, then it's an update)
        e.preventDefault();
        setOpenBackdrop(true);
        if (trimesterGoal._id) {
            const id = trimesterGoal._id

            /*setTrimesterGoal({
                ...trimesterGoal,
                name: trimesterGoal.name,
                estimatedNumberedGoal: trimesterGoal.estimatedNumberedGoal,
                realNumberedGoal: trimesterGoal.realNumberedGoal,
                note: trimesterGoal.note,
                percentageAchieved: trimesterGoal.percentageAchieved,
                trimester: trimesterGoal.trimester,
                yearlyGoalID: trimesterGoal.yearlyGoalID,
            })*/

            const updatedTrimesterGoal = {
                ...trimesterGoal,
                name: trimesterGoal.name,
                estimatedNumberedGoal: trimesterGoal.estimatedNumberedGoal,
                realNumberedGoal: trimesterGoal.realNumberedGoal,
                note: "",
                percentageAchieved: trimesterGoal.percentageAchieved,
                trimester: trimesterGoal.trimester,
                yearlyGoalID: trimesterGoal.yearlyGoalID,
                comment: trimesterGoal.comment,
            }

            /*setTrimesterGoal({
                ...trimesterGoal,
                name: trimesterGoal.name,
                estimatedNumberedGoal: trimesterGoal.estimatedNumberedGoal,
                realNumberedGoal: trimesterGoal.realNumberedGoal,
                note: "",
                percentageAchieved: trimesterGoal.percentageAchieved,
                trimester: trimesterGoal.trimester,
                yearlyGoalID: trimesterGoal.yearlyGoalID,
                comment: "",
            })*/

            updateTrimesterGoal(updatedTrimesterGoal, id, 2);
        } else {
            /*setTrimesterGoal({
                ...trimesterGoal,
                name: trimesterGoal.name,
                estimatedNumberedGoal: trimesterGoal.estimatedNumberedGoal,
                realNumberedGoal: trimesterGoal.realNumberedGoal,
                note: "",
                percentageAchieved: trimesterGoal.percentageAchieved,
                trimester: trimesterGoal.trimester,
                yearlyGoalID: trimesterGoal.yearlyGoalID,
            })*/

            const updatedTrimesterGoal = {
                ...trimesterGoal,
                name: trimesterGoal.name,
                estimatedNumberedGoal: trimesterGoal.estimatedNumberedGoal,
                realNumberedGoal: trimesterGoal.realNumberedGoal,
                note: "",
                percentageAchieved: trimesterGoal.percentageAchieved,
                trimester: trimesterGoal.trimester,
                yearlyGoalID: trimesterGoal.yearlyGoalID,
                comment: trimesterGoal.comment,
            }

            // Dispatch the goal using the action created in store
            addTrimesterGoal(updatedTrimesterGoal, 2);
        }

        // Reset todo values
        /*setTrimesterGoal({
            ...trimesterGoal,
            name: "",
            estimatedNumberedGoal: null,
            realNumberedGoal: 0,
            note: "",
            percentageAchieved: trimesterGoal.percentageAchieved,
            trimester: "t1",
            yearlyGoalID: "",
        })*/


        setTimeout(() => {
            if (locationComponent == "Intro") {
                setActiveStep((activeStep) => activeStep + 1);
                const updatedStep = {
                    _id: auth._id,
                    stepIntro: 4,
                };
                numberStepIntro(updatedStep);
            } else {
                const oneModaltodo = document.querySelector(".modal__views");
                oneModaltodo.classList.remove("active-modal");
            }
        }, 2000);

    }

    useEffect(() => {
        if (isAddComponent == true && locationComponent != "Intro") {
            setTrimesterGoal({
                type: 2,
                estimatedNumberedGoal: "",
                name: "",
                note: "",
                percentageAchieved: 0,
                active: true,
                yearlyGoalID: "",
                trimester: "",
                deleteBtn: true,
                comment: "",
            });
            setIsUpdate(false)
        }
    }, [isAddComponent, trimesterGoals]);


    return (
        <>
            <form autoComplete="off" className="goal__add-Form" onSubmit={submitHandler}>
                <div className="goal__input">
                    <TextField
                        id="estimatedNumber"
                        label="Nombre"
                        variant="outlined"
                        type="number"
                        autoFocus
                        fullWidth
                        step="1"
                        value={trimesterGoal.estimatedNumberedGoal}
                        onChange={(e) => setTrimesterGoal({ ...trimesterGoal, estimatedNumberedGoal: Math.round(e.target.value) })}
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        id="name"
                        label="Objectif"
                        variant="outlined"
                        fullWidth
                        value={trimesterGoal.name}
                        onChange={(e) => setTrimesterGoal({ ...trimesterGoal, name: e.target.value })}
                        InputProps={{
                            readOnly: isUptate,
                        }}
                        required
                    />
                </div>

                <div className="contact__label">
                    <TextField
                        id="trimesterComment"
                        label="Commentaire"
                        multiline
                        rowsMax="3"
                        value={trimesterGoal.comment}
                        onChange={(e) => setTrimesterGoal({ ...trimesterGoal, comment: e.target.value })}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                </div>

                <span className="contact__label">Reliez un objectif annuel (obligatoire)</span>
                <Select
                    className="todo__input"
                    disableUnderline
                    classes={{ root: minimalSelectClasses.select }}
                    MenuProps={menuProps}
                    IconComponent={iconComponent}
                    style={{ minWidth: 100 }}
                    value={trimesterGoal.yearlyGoalID}
                    onChange={(e) => setTrimesterGoal({ ...trimesterGoal, yearlyGoalID: e.target.value })}
                >
                    {yearlyGoals && yearlyGoals.map((y) => {
                        return (
                            <MenuItem
                                value={y._id}
                                className={y.active == true ? "activeGoal" : "notActiveGoal"}
                                disabled={y.isCompleted}
                            >{y.estimatedNumberedGoal} {y.name} {y.isCompleted ? <>(Completé)</> : <></>}</MenuItem>
                        )
                    })}
                </Select>

                {isAddComponent &&
                    <>
                        <span className="contact__label" >Choisissez un trimestre pour cet objectif (obligatoire)</span>
                        <Select
                            className="todo__input"
                            disableUnderline
                            classes={{ root: minimalSelectClasses.select }}
                            MenuProps={menuProps}
                            IconComponent={iconComponent}
                            style={{ minWidth: 100 }}
                            value={trimesterGoal.trimester}
                            disabled={!isAddComponent}
                            onChange={(e) => setTrimesterGoal({ ...trimesterGoal, trimester: e.target.value })}
                        >
                            {trimesterName
                                .map((tn) => (
                                    <MenuItem
                                        value={tn.value}
                                        disabled={!moment(moment(tn.endDay, "DD/MM/YYYY").format("YYYY-MM-DD")).isSameOrAfter(moment(new Date()).format("YYYY-MM-DD"))}
                                    >{tn.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </>
                }

                <Button variant="contained" color="primary" className="submitButton" type="submit">
                    <Send />
                </Button>
            </form>
        </>
    )
}

export default connect(null, { addTrimesterGoal, updateTrimesterGoal, getYearlyGoals, numberStepIntro })(AddTrimesterGoalScreen);