import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Box from "@material-ui/core/Box";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Astuce from "./Astuce";
import { useDispatch, useSelector } from "react-redux";
import MobileHeader from '../Header/HeaderMobile'

import { getAvailableTipsAndToolsByUser } from "../../../actions/tipsToolsActions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 600,
        backgroundColor: theme.palette.background.paper,
    },
    list: {
        display: "flex",
        justifyContent: "center",
    },
}));

function ListAstuceScreen({ getAvailableTipsAndToolsByUser, setOpenBackdrop }) {
    const astuces = useSelector((state) => state.tipsTools.userTipsAndTools);
    const classes = useStyles();

    useEffect(() => {
        getAvailableTipsAndToolsByUser();
    }, [])

    //removebackdrop
    useEffect(() => {
        setOpenBackdrop(false);

    }, [astuces])

    //set backdrop
    useEffect(() => {
        setOpenBackdrop(true);
    }, [])

    return (
        <section>
            <div className={classes.list}>
                <List className={classes.root}>
                    {astuces && astuces.map((a, i) => {
                        return (
                            astuces.length === i + 1 ? (
                                <Astuce key={a.id} tips={a} last={true} />
                            ) : (
                                <Astuce key={a.id} tips={a} last={false} />
                            )
                        );
                    })}
                </List>
            </div>
        </section>
    );
}

export default connect(null, { getAvailableTipsAndToolsByUser })(ListAstuceScreen);
