import React, { useState, useEffect, Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, ButtonGroup, Button, TextField } from "@material-ui/core";
import { Create, Delete, CheckCircle, Send, StopSharp } from "@material-ui/icons";
import CancelIcon from '@material-ui/icons/Warning';
import moment from "moment";

import { connect } from 'react-redux';

import "../CSS/WeeklyGoalScreen.css";

import { updateWeeklyGoal, deleteWeeklyGoal } from '../../../../../actions/weeklyGoalActions'
import { addTodo } from '../../../../../actions/todoActions';
import { getPoints } from '../../../../../actions/pointActions'

//card
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CachedIcon from '@material-ui/icons/Cached';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// modal todo
import { DatePicker, LocalizationProvider } from "@material-ui/pickers"
import MomentAdapter from "@material-ui/pickers/adapter/moment";

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import $ from 'jquery';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    root2: {
        width: '100%',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const WeeklyGoalScreen = ({
    weeklyGoal, setWeeklyGoal, trimesterGoal, updateWeeklyGoal, deleteWeeklyGoal,
    setIsUpdate, setOpenBackdrop, setIsAddComponent,
    setOpenConfirmation, setIdToDelete,
    addTodo, getPoints,
    trimester
}) => {
    const auth = useSelector((state) => state.auth.user)

    const yearlyGoals = useSelector(state => state.yearlyGoal);
    const trimesterGoals = useSelector(state => state.trimesterGoal);
    const weeklyGoals = useSelector((state) => state.weeklyGoal);
    const [realisedWeeklyGoal, setRealisedWeeklyGoal] = useState({
        ...weeklyGoal,
        realNumberedGoal: weeklyGoal.realNumberedGoal
    });
    const [noteWeeklyGoal, setNoteWeeklyGoal] = useState({
        ...weeklyGoal,
        note: weeklyGoal.note
    })
    const [checked, setChecked] = useState(weeklyGoal.weekToTodo);
    const [checkChanged, setCheckChanged] = useState(false);
    const [todoDateToSet, setTodoDateToSet] = useState(new Date());
    const [todoEstimatedTime, setTodoEstimatedTime] = useState(1);

    const [expanded, setExpanded] = useState(false);
    const [expanded2, setExpanded2] = useState(false);
    const handleExpandClick = () => {
        setExpanded2(false);
        setExpanded(!expanded);
    };
    const handleExpandClick2 = () => {
        setExpanded(false);
        setExpanded2(!expanded2);
    };

    const updateClickHandler = (popupState) => {
        // As we are using the same form as "ADD" method, 
        // setting setWeeklyGoal here will auto-prefill the different inputs
        // So that user can change only what he wants
        setWeeklyGoal({ ...weeklyGoal });

        /*window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })*/
        popupState.close();
        $(".weeklyADD").trigger("click");
        setIsAddComponent(false)
    }

    const realisedWeeklyGoalHandler = () => {
        if (weeklyGoal._id) {
            setOpenBackdrop(true);
            const id = weeklyGoal._id

            const updatedRealisedWeeklyGoal = {
                ...weeklyGoal,
                realNumberedGoal: realisedWeeklyGoal.realNumberedGoal,
            }

            updateWeeklyGoal(updatedRealisedWeeklyGoal, id, 3);
            setExpanded(false)
        }
    }

    const noteWeeklyGoalHandler = () => {
        if (weeklyGoal._id) {
            setOpenBackdrop(true);
            const id = weeklyGoal._id

            setWeeklyGoal({
                ...weeklyGoal,
                note: noteWeeklyGoal.note
            })

            const updatedNoteWeeklyGoal = {
                ...weeklyGoal,
                note: noteWeeklyGoal.note,
            }

            updateWeeklyGoal(updatedNoteWeeklyGoal, id, 3);

            setWeeklyGoal({
                ...weeklyGoal,
                note: ""
            })

            setExpanded(false)
        }
    }

    const deleteHandler = (id) => {
        setOpenBackdrop(true);
        deleteWeeklyGoal(id, 3);
    }

    const stopHandler = () => {
        if (weeklyGoal._id) {
            setOpenBackdrop(true);
            const id = weeklyGoal._id

            setWeeklyGoal({
                ...weeklyGoal,
                active: false
            })

            const updatedActiveWeeklyGoal = {
                ...weeklyGoal,
                active: false,
            }

            updateWeeklyGoal(updatedActiveWeeklyGoal, id, 3);

            setWeeklyGoal({
                ...weeklyGoal,
                active: true
            })
        }
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setCheckChanged(event.target.checked);
    };

    const attachWeeklyToTodo = (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        // Add new todo
        const attachedTodo = {
            name: weeklyGoal.name,
            estimatedDuration: todoEstimatedTime,
            isCompleted: false,
            todoDate: todoDateToSet,
            weeklyGoal: weeklyGoal._id,
            comment: "",
            weekToTodo: true,
        }

        addTodo(attachedTodo, 0, todoDateToSet);
        setTimeout(function () { getPoints(auth._id) }, 5000);

        // Update weekly goal to be linked with the todo
        if (weeklyGoal._id) {
            const id = weeklyGoal._id

            const updatedRealisedWeeklyGoal = {
                ...weeklyGoal,
                weekToTodo: true,
            }

            updateWeeklyGoal(updatedRealisedWeeklyGoal, id, 3);
        }
        setExpanded2(false)
    };

    const classes = useStyles();


    return (
        <>
            <Card className="my__oneCard" style={{ backgroundColor: weeklyGoal.color }}>
                <CardHeader
                    className="cardHeader"
                    avatar={
                        weeklyGoal.isCompleted ? (
                            <CheckCircle className="isCompleted" />
                        ) : !weeklyGoal.active ? (
                            <CancelIcon className="notActive" />
                        ) : !weeklyGoal.deleteBtn ? (
                            <CachedIcon className="isRunning" />
                        ) : (
                            <RadioButtonUncheckedIcon className="action inside--card-icon" />
                        )
                    }
                    action={
                        <>
                            {/* {trimester == auth.currentTrimester && */}

                            <PopupState variant="popover" popupId="demo-popup-popover" >
                                {(popupState) => (
                                    <div >
                                        <MoreVertIcon {...bindTrigger(popupState)} className="inside--card-icon" />
                                        <Popover className="popover--container"
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Box p={1}>
                                                <div>
                                                    {weeklyGoal.isCompleted ? (
                                                        <Button>
                                                            <CheckCircle className="isCompleted" />
                                                        </Button>
                                                    ) : !weeklyGoal.active ? (
                                                        <CancelIcon className="notActive" />
                                                    ) : weeklyGoal.deleteBtn ? (
                                                        <>
                                                            <Button onClick={() => { updateClickHandler(popupState); setIsUpdate(false) }}>
                                                                <Create color="primary" />
                                                            </Button>
                                                            <Button onClick={() => { setIdToDelete(weeklyGoal._id); setOpenConfirmation(true) }}>
                                                                <Delete className="isActive" />
                                                            </Button>
                                                            {/* <Button onClick={() => deleteHandler(weeklyGoal._id)}>
                                                                <Delete className="isActive" />
                                                            </Button> */}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button onClick={() => { updateClickHandler(popupState); setIsUpdate(true) }}>
                                                                <Create color="primary" />
                                                            </Button>
                                                            <Button onClick={() => stopHandler()}>
                                                                <StopSharp className="isActive" />
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>

                                            </Box>
                                        </Popover>
                                    </div>
                                )}
                            </PopupState>

                        </>
                    }
                    title={
                        <div>
                            <h3 className="qualification__title">  {weeklyGoal.estimatedNumberedGoal} {" "} {weeklyGoal.name}</h3>
                            {weeklyGoal.comment ? (
                                <div className="">
                                    {weeklyGoal.comment}
                                </div>
                            ) : (
                                <>
                                </>
                            )}
                        </div>
                    }
                    subheader={
                        <div>
                            <div className="goal__card-content ">
                                <div >
                                    <span className="inside--card">
                                        <i className="uil uil-link"></i>
                                        Objectif trimestriel: {trimesterGoal.estimatedNumberedGoal} {trimesterGoal.name}
                                    </span>
                                    <span className="inside--card">
                                        <i className="uil uil-process"></i>
                                        Achevé: {weeklyGoal.percentageAchieved}%
                                    </span>
                                    <span className="inside--card">
                                        <i className="uil uil-calender"></i>
                                        Ajouté: {moment(weeklyGoal.createdAt).fromNow()}
                                    </span>
                                    {weeklyGoal.note ?
                                        (<span className="inside--card inside__card--note">
                                            <i className="uil uil-notes "></i>
                                            {weeklyGoal.note}
                                        </span>) : (<></>)
                                    }
                                </div>

                                <div className="">

                                    {weeklyGoal.estimatedNumberedGoal == 1 ? (
                                        <Tooltip title="Ajouter comme tache journalière">
                                            <IconButton
                                                className={clsx(classes.expand, {
                                                    [classes.expandOpen]: expanded2,
                                                })}
                                                onClick={handleExpandClick2}
                                                aria-expanded={expanded2}
                                                aria-label="show more"
                                                disabled={weeklyGoal.weekToTodo}
                                            >
                                                {/* <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checked}
                                                            onChange={handleChange}
                                                            color="primary"
                                                            disabled={weeklyGoal.weekToTodo}
                                                        />
                                                    }
                                                /> */}
                                                <PlaylistAddCheckIcon />

                                            </IconButton>
                                        </Tooltip>

                                    ) : (<></>)}


                                    <IconButton
                                        className={clsx(classes.expand, {
                                            [classes.expandOpen]: expanded,
                                        })}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                        disabled={weeklyGoal.isCompleted}
                                    >

                                        {weeklyGoal.isCompleted ? (

                                            <></>
                                        ) : !weeklyGoal.active ? (
                                            <NoteAddIcon className="isCompleted" />
                                        ) : weeklyGoal.outOfTime ? (
                                            <NoteAddIcon className="isCompleted" />
                                        ) : weeklyGoal.deleteBtn ? (
                                            <AssignmentTurnedInIcon className="isCompleted" />
                                        ) : (
                                            <>
                                                <AssignmentTurnedInIcon className="isCompleted" />
                                            </>
                                        )}
                                    </IconButton>




                                </div>
                            </div>
                        </div>
                    }
                />
                <Collapse in={expanded} timeout="auto" unmountOnExit >

                    <CardContent className="cardContent">

                        <div className="goal__card-input">

                            {weeklyGoal.active ? (
                                <>
                                    {/*} {{!weeklyGoal.outOfTime ? (
                                        <>*/}
                                    {!weeklyGoal.isCompleted ? (
                                        <>
                                            <div className="inside--card-right">
                                                <TextField
                                                    id="realisedNumber"
                                                    label="Nombre"
                                                    variant="outlined"
                                                    type="number"
                                                    size="small"
                                                    style={{ width: '100%' }}
                                                    step="1"
                                                    value={realisedWeeklyGoal.realNumberedGoal}
                                                    onChange={(e) => setRealisedWeeklyGoal({ ...realisedWeeklyGoal, realNumberedGoal: Math.round(e.target.value) })}
                                                    required
                                                />
                                                <Button onClick={() => realisedWeeklyGoalHandler()}>
                                                    <Send color="primary" />
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="inside--card-right">
                                                <TextField
                                                    id="note"
                                                    label="Pourquoi avez-vous stoppé cet objectif?"
                                                    variant="outlined"
                                                    type="text"
                                                    size="small"
                                                    style={{ width: '100%' }}
                                                    value={noteWeeklyGoal.note}
                                                    onChange={(e) => setNoteWeeklyGoal({ ...noteWeeklyGoal, note: e.target.value })}
                                                    required
                                                />
                                                <Button onClick={() => noteWeeklyGoalHandler()}>
                                                    <Send color="primary" />
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                    {/*} </>
                                    ) : (
                                        <>
                                            <div className="inside--card-right">
                                                <TextField
                                                    id="note"
                                                    label="Pourquoi n'avez-vous pas achevé cet objectif dans le temps imparti indiqué?"
                                                    variant="outlined"
                                                    type="text"
                                                    size="small"
                                                    style={{ width: '100%' }}
                                                    value={noteWeeklyGoal.note}
                                                    onChange={(e) => setNoteWeeklyGoal({ ...noteWeeklyGoal, note: e.target.value })}
                                                    required
                                                />
                                                <Button onClick={() => noteWeeklyGoalHandler()}>
                                                    <Send color="primary" />
                                                </Button>
                                            </div>
                                        </>
                                    )}*/}
                                </>
                            ) : (
                                <>
                                    {!weeklyGoal.isCompleted ? (
                                        <div className="inside--card-right">
                                            <TextField
                                                id="note"
                                                label="Pourquoi avez-vous stoppé cet objectif?"
                                                variant="outlined"
                                                type="text"
                                                size="small"
                                                style={{ width: '100%' }}
                                                value={noteWeeklyGoal.note}
                                                onChange={(e) => setNoteWeeklyGoal({ ...noteWeeklyGoal, note: e.target.value })}
                                                required
                                            />
                                            <Button onClick={() => noteWeeklyGoalHandler()}>
                                                <Send color="primary" />
                                            </Button>
                                        </div>
                                    ) : (<></>)

                                    }

                                </>
                            )}
                        </div>
                    </CardContent>
                </Collapse>

                <Collapse in={expanded2} timeout="auto" unmountOnExit >
                    <CardContent className="cardContent">

                        <div className="goal__card-input">
                            <div className="inside--card-caption">
                                <Typography variant="caption" display="block" gutterBottom>
                                    Information de la tache journalière
                                </Typography>
                            </div>
                            {weeklyGoal.estimatedNumberedGoal == 1 ? (
                                <>
                                    <form autoComplete="off" className="inside--card-right" onSubmit={attachWeeklyToTodo}>
                                        <Grid container className={classes.root2} spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="estimatedDuration"
                                                    label="Temps estimé en minutes"
                                                    variant="outlined"
                                                    type="number"
                                                    fullWidth
                                                    min="0"
                                                    stpe="1"
                                                    value={todoEstimatedTime}
                                                    onChange={(e) => setTodoEstimatedTime(Math.round(e.target.value))}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    required
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <LocalizationProvider dateAdapter={MomentAdapter}>
                                                    <DatePicker
                                                        minDate={new Date()}
                                                        maxDate={new Date(moment(weeklyGoal.endDate, "DD/MM/YYYY").format("YYYY-MM-DD"))}
                                                        label="Choisissez une date"
                                                        value={todoDateToSet}
                                                        onChange={(newValue) => {
                                                            setTodoDateToSet(newValue);
                                                        }}
                                                        renderInput={(props) => <TextField {...props} helperText="jj/mm/aaaa" />}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <Button color="primary" className="submitButton" type="submit">
                                                    <Send />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>

                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </CardContent>
                </Collapse>
                <Box p={1}> <div className="inside--card-date">
                    Dernière modification : {moment(weeklyGoal.updatedAt).fromNow()}
                </div>
                </Box>
            </Card>
            <div className="my__card">
                <div>
                    <h3>Objectif</h3>
                    {weeklyGoal.isCompleted ? (
                        <>
                            <Typography variant="subtitle1" className="checked">
                                {weeklyGoal.estimatedNumberedGoal} {" "} {weeklyGoal.name}
                            </Typography>
                            <Typography variant="subtitle1">
                                Achieved: {weeklyGoal.percentageAchieved}%
                            </Typography>
                        </>
                    ) : !weeklyGoal.active ? (
                        <>
                            <Typography variant="subtitle1" className="checked">
                                {weeklyGoal.estimatedNumberedGoal} {" "} {weeklyGoal.name}
                            </Typography>
                            <Typography variant="subtitle1">
                                Achieved: {weeklyGoal.percentageAchieved}%
                            </Typography>
                            <Typography variant="subtitle1">
                                Note: {weeklyGoal.note}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography variant="subtitle1" >
                                {weeklyGoal.estimatedNumberedGoal} {weeklyGoal.name}
                            </Typography>
                            {weeklyGoal.percentageAchieved > 0 &&
                                <Typography variant="subtitle1">
                                    Currently Achieved: {weeklyGoal.percentageAchieved}%
                                </Typography>
                            }
                        </>
                    )}
                    <Typography variant="body2" className="moreStyle">
                        Trimester goal: {trimesterGoal.estimatedNumberedGoal} {trimesterGoal.name}
                    </Typography>
                    <Typography variant="body2" className="moreStyle">
                        Added: {moment(weeklyGoal.createdAt).fromNow()}
                    </Typography>

                    <ButtonGroup
                        size="small"
                        aria-label="outlined primary button group"
                    >
                        {weeklyGoal.isCompleted ? (
                            <Button>
                                <CheckCircle className="isCompleted" />
                            </Button>
                        ) : !weeklyGoal.active ? (
                            <div>
                                <Button >
                                    <CancelIcon className="notActive" />
                                </Button>
                            </div>
                        ) : weeklyGoal.deleteBtn ? (
                            <div>
                                <Button >
                                    <CheckCircle color="action" />
                                </Button>
                                <Button onClick={() => updateClickHandler()}>
                                    <Create color="primary" />
                                </Button>
                                <Button onClick={() => deleteHandler(weeklyGoal._id)}>
                                    <Delete className="isActive" />
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <Button >
                                    <CheckCircle color="action" />
                                </Button>
                                <Button onClick={() => updateClickHandler()}>
                                    <Create color="primary" />
                                </Button>
                                <Button onClick={() => stopHandler()}>
                                    <StopSharp className="isActive" />
                                </Button>
                            </div>
                        )}
                    </ButtonGroup>
                </div>
                <div>
                    {weeklyGoal.active ? (
                        <>
                            {!weeklyGoal.isCompleted &&
                                <>
                                    <h3>Réalisé</h3>
                                    <Typography variant="subtitle1">
                                        <TextField
                                            id="realisedNumber"
                                            label="Nombre"
                                            variant="outlined"
                                            type="number"
                                            size="small"
                                            style={{ width: 100 }}
                                            step="1"
                                            value={realisedWeeklyGoal.realNumberedGoal}
                                            onChange={(e) => setRealisedWeeklyGoal({ ...realisedWeeklyGoal, realNumberedGoal: Math.round(e.target.value) })}
                                            required
                                        />
                                        / {weeklyGoal.estimatedNumberedGoal}

                                        <Button onClick={() => realisedWeeklyGoalHandler()}>
                                            <Send color="primary" />
                                        </Button>
                                    </Typography>
                                </>
                            }
                        </>
                    ) : (
                        <>
                            <h3>Motif</h3>
                            <Typography variant="subtitle1">
                                <TextField
                                    id="note"
                                    label="Pourquoi avez-vous stoppé cet objectif?"
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    style={{ width: 250 }}
                                    value={noteWeeklyGoal.note}
                                    onChange={(e) => setNoteWeeklyGoal({ ...noteWeeklyGoal, note: e.target.value })}
                                    required
                                />
                                <Button onClick={() => noteWeeklyGoalHandler()}>
                                    <Send color="primary" />
                                </Button>
                            </Typography>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default connect(null, { updateWeeklyGoal, deleteWeeklyGoal, addTodo, getPoints })(WeeklyGoalScreen);