import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from "react-router-dom";
import AddTrimesterGoalScreen from "./AddTrimesterGoalScreen";
import ListTrimesterGoalScreen from "./ListTrimesterGoalScreen";
import { connect } from 'react-redux';

//modal
import Mymodal from "../../../../modal/Mymodal";
import MyAddbutton from "../../../../buttons/MyAddButton/MyAddbutton";
import ConfirmationModal from '../../../../modal/ConfirmationModal';
import IconButton from '@material-ui/core/IconButton';

const TrimesterGoalsScreen = ({ history, propsAdd = "trimesterADD", setOpenBackdrop, isAddComponent, setIsAddComponent }) => {
    const auth = useSelector((state) => state.auth.user);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [idToDelete, setIdToDelete] = useState("");
    const [trimesterGoal, setTrimesterGoal] = useState({
        type: 2,
        estimatedNumberedGoal: "",
        name: "",
        note: "",
        percentageAchieved: 0,
        active: true,
        yearlyGoalID: "",
        trimester: "",
        deleteBtn: true,
        comment: "",
    })

    useEffect(() => {
        //dispatch(loadUser());
    }, [])

    //modal
    const [isUptate, setIsUpdate] = useState(false);

    return (
        <>
            <MyAddbutton propsAdd={propsAdd} />

            <ConfirmationModal
                openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation}
                confirmationTitle="Suppression d'un objectif trimestriel"
                confirmationText="En supprimant cet objectif trimestriel, vous allez également supprimer tous les objectifs hebdomadaires liés à celui-ci.
                                    Etes-vous sur de vouloir supprimer cet objectif?"
                component="Trimester Goal"
                idToDelete={idToDelete}
                setOpenBackdrop={setOpenBackdrop}
            />

            <Mymodal className="goal--modal" modalTitle="Objectif trimestriel " setIsAddComponent={setIsAddComponent}
                modalContent={
                    <>
                        <AddTrimesterGoalScreen trimesterGoal={trimesterGoal} setTrimesterGoal={setTrimesterGoal} isUptate={isUptate} setIsUpdate={setIsUpdate} locationComponent="goalScreen" setOpenBackdrop={setOpenBackdrop} isAddComponent={isAddComponent} />
                    </>}
            />

            <ListTrimesterGoalScreen
                setTrimesterGoal={setTrimesterGoal}
                isUptate={isUptate} setIsUpdate={setIsUpdate}
                setOpenBackdrop={setOpenBackdrop}
                setIsAddComponent={setIsAddComponent}
                setOpenConfirmation={setOpenConfirmation}
                setIdToDelete={setIdToDelete}
            />
        </>
    );
};

export default TrimesterGoalsScreen;