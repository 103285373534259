import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from "axios";
import "../CSS/ForgotPasswordScreen.css";
import { Link, Redirect } from 'react-router-dom';
import logoLg from '../../../../img/logoLg.png'

const ForgotPasswordScreen = ({ history }) => {
    const auth = useSelector(state => state.auth.user);

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    useEffect(() => {
        // If an authToken alreafy exists in the localstorage, it means that the user is
        // already connected so just redirect him/her to the "/" path
        if (localStorage.getItem("authToken")) {
            history.push("/");
        }
    }, [history, auth]);

    const forgotPasswordHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.post(
                "/api/auth/forgotpassword",
                { email },
                config
            );


            if (data.success) {
                <Redirect to="/login" />
            }

            setSuccess("Email de réinitialization envoyé. Veuillez vérifier vos mails");

            setEmail("");

        } catch (error) {
            setError(error.response.data.error);
            setEmail("");
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    return (
        <div className="welcome">
            <div className="forgotpassword-screen">
                <form
                    onSubmit={forgotPasswordHandler}
                    className="forgotpassword-screen__form">

                    <img src={logoLg} alt="" className="login__img" />

                    <h3 className="forgotpassword-screen__title">Mot de passe oublié</h3>
                    {error && <span className="error-message">{error}</span>}
                    {success ? (
                        <>
                            <span className="success-message">
                                {success} <Link to="/login">Se connecter</Link>
                            </span>
                        </>
                    ) : (
                        <>
                            <div className="form-group">
                                <p className="forgotpassword-screen__subtext">
                                    Veuillez entrer votre adresse mail pour recevoir le token de réinitialisation de mot de passe.
                                    Veillez vous assurer que l'email que vous avez entré correspond l'email vous permettant de vous connecter à l'application.
                                </p>
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    required
                                    id="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} />
                            </div>

                            <button type="submit" className="button  button__submit">
                                Envoyer
                            </button>

                            <span className="register-screen__subtext">
                                Vous avez déjà un compte ? <Link to="/login">Se connecter</Link>
                            </span>
                        </>
                    )}
                </form>
            </div>
        </div>

    );
};

export default ForgotPasswordScreen;