import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from "react-router-dom";
import { TextField, Button, Select, MenuItem, Checkbox, FormGroup, FormControlLabel, FormControl, Popover, Box } from '@material-ui/core';
import { useMinimalSelectStyles } from '@mui-treasury/styles/select/minimal';
import { Send, ExpandMore, Info } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';
import { connect } from 'react-redux';

import "../CSS/AddWeeklyGoalScreen.css";
import "react-datepicker/dist/react-datepicker.css";

import { addWeeklyGoal, updateWeeklyGoal } from '../../../../../actions/weeklyGoalActions'
import { getTrimesterGoals } from '../../../../../actions/trimesterGoalActions'
import { getYearlyGoals } from '../../../../../actions/yearlyGoalActions'
import { numberStepIntro } from '../../../../../actions/authActions'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

const AddWeeklyGoalScreen = ({ weeklyGoal, setWeeklyGoal, history, addWeeklyGoal, updateWeeklyGoal, getTrimesterGoals, getYearlyGoals, isUptate, setIsUpdate, locationComponent, activeStep, setActiveStep, numberStepIntro, setOpenBackdrop, isAddComponent }) => {
    const auth = useSelector(state => state.auth.user);
    const yearlyGoals = useSelector((state) => state.yearlyGoal);
    const trimesterGoals = useSelector((state) => state.trimesterGoal);
    const weeklyGoals = useSelector(state => state.weeklyGoal);
    const [weeklyName, setWeeklyName] = useState([]);
    const [weeklyNameInitial, setWeeklyNameInitial] = useState([]);
    const [selectedWeek, setSelectedWeek] = useState(true);
    const today = moment(new Date()).format("DD/MM/YYYY").toString();

    const getWeekDates = () => {
        let objWeek;

        for (let x = 0; x < auth.trimesters.t1.weeks.length; x++) {
            objWeek = {
                trimester: "t1",
                value: "w" + (x + 1),
                name: "Semaine " + (x + 1),
                startDay: auth.trimesters.t1.weeks[x].startDay,
                endDay: auth.trimesters.t1.weeks[x].endDay,
                checked: false,
            }

            if (moment(new Date(objWeek.endDay, "DD/MM/YYYY")).format("DD/MM/YYYY") > moment(new Date()).format("DD/MM/YYYY")) {
                weeklyName.push(objWeek);
            }
        }

        for (let x = 0; x < auth.trimesters.t2.weeks.length; x++) {
            objWeek = {
                trimester: "t2",
                value: "w" + (x + 1),
                name: "Semaine " + (x + 1),
                startDay: auth.trimesters.t2.weeks[x].startDay,
                endDay: auth.trimesters.t2.weeks[x].endDay,
                checked: false,
            }

            if (moment(new Date(objWeek.endDay, "DD/MM/YYYY")).format("DD/MM/YYYY") > moment(new Date()).format("DD/MM/YYYY")) {
                weeklyName.push(objWeek);
            }
        }

        for (let x = 0; x < auth.trimesters.t3.weeks.length; x++) {
            objWeek = {
                trimester: "t3",
                value: "w" + (x + 1),
                name: "Semaine " + (x + 1),
                startDay: auth.trimesters.t3.weeks[x].startDay,
                endDay: auth.trimesters.t3.weeks[x].endDay,
                checked: false,
            }

            if (moment(new Date(objWeek.endDay, "DD/MM/YYYY")).format("DD/MM/YYYY") > moment(new Date()).format("DD/MM/YYYY")) {
                weeklyName.push(objWeek);
            }
        }

        for (let x = 0; x < auth.trimesters.t4.weeks.length; x++) {
            objWeek = {
                trimester: "t4",
                value: "w" + (x + 1),
                name: "Semaine " + (x + 1),
                startDay: auth.trimesters.t4.weeks[x].startDay,
                endDay: auth.trimesters.t4.weeks[x].endDay,
                checked: false,
            }

            if (moment(new Date(objWeek.endDay, "DD/MM/YYYY")).format("DD/MM/YYYY") > moment(new Date()).format("DD/MM/YYYY")) {
                weeklyName.push(objWeek);
            }
        }
        setWeeklyNameInitial(weeklyName);
    }

    const minimalSelectClasses = useMinimalSelectStyles();
    const iconComponent = (props) => {
        return (
            <ExpandMore className={props.className + " " + minimalSelectClasses.icon} />
        )
    };
    const menuProps = {
        classes: {
            paper: minimalSelectClasses.paper,
            list: minimalSelectClasses.list
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };

    useEffect(() => {
        getYearlyGoals();
        getTrimesterGoals();
        getWeekDates();
    }, [])

    const testHandler = async (e) => {
        e.preventDefault();
        const selectedWeek = weeklyName.filter((wn) => wn.checked == true);

        if (selectedWeek.length > 0) {
            submitHandler(e);
        } else {
            alert("Vous devez choisir au moins une semaine pour l'objectif");
        }
    }

    const submitHandler = async (e) => {
        // Check if it's an update or a add (if _id exists, then it's an update)
        setOpenBackdrop(true);
        if (weeklyGoal._id) {
            const id = weeklyGoal._id

            const updatedWeeklyGoal = {
                ...weeklyGoal,
                name: weeklyGoal.name,
                estimatedNumberedGoal: weeklyGoal.estimatedNumberedGoal,
                realNumberedGoal: weeklyGoal.realNumberedGoal,
                note: "",
                percentageAchieved: weeklyGoal.percentageAchieved,
                trimester: auth.currentTrimester,
                week: weeklyGoal.week,
                trimesterGoalID: weeklyGoal.trimesterGoalID,
                comment: weeklyGoal.comment,
            }

            updateWeeklyGoal(updatedWeeklyGoal, id, 3);

        } else {
            weeklyName
                .filter((wn) => wn.checked == true)
                .map((w) => {
                    const updatedWeeklyGoal = {
                        ...w,
                        name: weeklyGoal.name,
                        estimatedNumberedGoal: weeklyGoal.estimatedNumberedGoal,
                        note: "",
                        percentageAchieved: weeklyGoal.percentageAchieved,
                        trimester: auth.currentTrimester,
                        week: w.value,
                        trimesterGoalID: weeklyGoal.trimesterGoalID,
                        comment: weeklyGoal.comment,
                    }

                    // Dispatch the goal using the action created in store
                    addWeeklyGoal(updatedWeeklyGoal, 3);
                })

            /*const updatedWeeklyGoal = {
                ...weeklyGoal,
                name: weeklyGoal.name,
                estimatedNumberedGoal: weeklyGoal.estimatedNumberedGoal,
                realNumberedGoal: weeklyGoal.realNumberedGoal,
                note: "",
                percentageAchieved: weeklyGoal.percentageAchieved,
                trimester: auth.currentTrimester,
                week: weeklyGoal.week,
                trimesterGoalID: weeklyGoal.trimesterGoalID,
                comment: weeklyGoal.comment,
            }
 
            // Dispatch the goal using the action created in store
            addWeeklyGoal(updatedWeeklyGoal, 3);*/


        }
        setTimeout(() => {
            if (locationComponent == "Intro") {
                setActiveStep((activeStep) => activeStep + 1);
                const updatedStep = {
                    _id: auth._id,
                    stepIntro: 5,
                };
                numberStepIntro(updatedStep);
            } else {
                const oneModaltodo = document.querySelector(".modal__views");
                oneModaltodo.classList.remove("active-modal");

            }
        }, 2000);

        setWeeklyGoal({
            type: 3,
            estimatedNumberedGoal: "",
            name: "",
            note: "",
            percentageAchieved: 0,
            active: true,
            trimesterGoalID: "",
            trimester: weeklyGoal.trimester,
            week: "",
            deleteBtn: true,
            comment: "",
        });

        //getWeekDates();
        setWeeklyName(weeklyNameInitial);

    }

    useEffect(() => {
        if (isAddComponent == true && locationComponent != "Intro") {
            setWeeklyGoal({
                type: 3,
                estimatedNumberedGoal: "",
                name: "",
                note: "",
                percentageAchieved: 0,
                active: true,
                trimesterGoalID: "",
                trimester: weeklyGoal.trimester,
                week: "",
                deleteBtn: true,
                comment: "",
            });
            setIsUpdate(false)
        }
    }, [isAddComponent, weeklyGoals]);

    const handleRecurrenceWeek = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <form autoComplete="off" className="goal__add-Form" onSubmit={testHandler}>
                <div className="goal__input">
                    <TextField
                        id="estimatedNumber"
                        label="Nombre"
                        variant="outlined"
                        type="number"
                        autoFocus
                        fullWidth
                        step="1"
                        value={weeklyGoal.estimatedNumberedGoal}
                        onChange={(e) => setWeeklyGoal({ ...weeklyGoal, estimatedNumberedGoal: Math.round(e.target.value) })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />
                    <TextField
                        id="name"
                        label="Objectif"
                        variant="outlined"
                        fullWidth
                        value={weeklyGoal.name}
                        onChange={(e) => setWeeklyGoal({ ...weeklyGoal, name: e.target.value })}
                        InputProps={{
                            readOnly: isUptate,
                        }}
                        required
                    />
                </div>

                <div className="contact__label">
                    <TextField
                        id="weeklyComment"
                        label="Commentaire"
                        multiline
                        rowsMax="3"
                        value={weeklyGoal.comment}
                        onChange={(e) => setWeeklyGoal({ ...weeklyGoal, comment: e.target.value })}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                </div>

                {/* {!isAddComponent &&
                    <label className="contact__label">*Ce champ ne peut etre changé lors d'une modification</label>
                } */}

                {isAddComponent &&
                    <>
                        <label className="contact__label">Reliez un objectif trimestriel (obligatoire)</label>
                        <Select
                            disableUnderline
                            classes={{ root: minimalSelectClasses.select }}
                            MenuProps={menuProps}
                            IconComponent={iconComponent}
                            style={{ minWidth: 100 }}
                            value={weeklyGoal.trimesterGoalID}
                            onChange={(e) => setWeeklyGoal({ ...weeklyGoal, trimesterGoalID: e.target.value })}
                            required
                            disabled={!isAddComponent}
                        >
                            {trimesterGoals && trimesterGoals.map((t) => {
                                return (
                                    <MenuItem
                                        value={t._id}
                                        className={` ${t.trimester == weeklyGoal.trimester ? "sameTrimester" : "notSameTrimester"} 
                                ${t.active ? "activeGoal" : "notActiveGoal"}`}
                                        disabled={t.isCompleted}
                                    >{t.estimatedNumberedGoal} {t.name} {t.isCompleted ? <>(Completé)</> : <></>}</MenuItem>
                                )
                            })}
                        </Select>
                    </>
                }

                {/* <Select
                    disableUnderline
                    classes={{ root: minimalSelectClasses.select }}
                    MenuProps={menuProps}
                    IconComponent={iconComponent}
                    style={{ minWidth: 100 }}
                    value={weeklyGoal.week}
                    onChange={(e) => setWeeklyGoal({ ...weeklyGoal, week: e.target.value })}
                >
                    {weeklyName && weeklyName.map(wn => {
                        return (
                            <MenuItem
                                value={wn.value}
                                className={wn.trimester == auth.currentTrimester ? "sameTrimester" : "notSameTrimester"}
                            >{wn.name} {wn.startDay} au {wn.endDay} </MenuItem>
                        )
                    })}
                </Select> */}

                {/* {!isAddComponent &&
                    <label className="contact__label">*Ce champ ne peut etre changé lors d'une modification</label>
                } */}

                {isAddComponent &&
                    <>
                        <label className="contact__label">Choisissez une/des semaine(s) pour cet objectif (obligatoire)</label>
                        <div className={locationComponent == "Intro" ? "weekNameRecurrenceIntro" : "weekNameRecurrence"}>
                            {weeklyName
                                .map((wn, i) => (
                                    <FormGroup>
                                        <FormControlLabel
                                            key={wn.name}
                                            value={wn.value}
                                            className={wn.trimester == weeklyGoal.trimester ? "sameTrimester" : "notSameTrimester"}
                                            control={
                                                <>
                                                    <Checkbox
                                                        checked={wn.checked}
                                                        onChange={(event) => {
                                                            let copyWeeklyName = [...weeklyName];
                                                            copyWeeklyName.splice(i, 1, {
                                                                ...weeklyName[i],
                                                                checked: event.target.checked
                                                            });

                                                            setWeeklyName(copyWeeklyName);

                                                            /*if (weeklyName.filter((wn) => wn.checked == true).length > 0) {
                                                                setSelectedWeek(false);
                                                            } else {
                                                                setSelectedWeek(true);
                                                            }*/
                                                        }}
                                                        color="primary"
                                                        disabled={!moment(moment(wn.endDay, "DD/MM/YYYY").format("YYYY-MM-DD")).isSameOrAfter(moment(new Date()).format("YYYY-MM-DD"))}
                                                    />

                                                    {/* <PopupState variant="popover" popupId="demo-popup-popover" >
                        {(popupState) => (
                            <div >
                                <Info {...bindTrigger(popupState)} className="inside--card-icon" />
                                <Popover className="popover--container"
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box p={1}>
                                        <div>
                                            <Button>
                                                <Info className="isActive" />
                                            </Button>
                                        </div>
                                    </Box>
                                </Popover>
                            </div>
                        )}
                    </PopupState> */}
                                                </>
                                            }
                                            label={`${wn.name}`}
                                        />
                                    </FormGroup>
                                ))}
                        </div>
                    </>
                }


                <Button
                    variant="contained"
                    color="primary"
                    className="submitButton"
                    type="submit"
                >
                    <Send />
                </Button>
            </form>
        </>
    )
}

export default connect(null, { addWeeklyGoal, updateWeeklyGoal, getTrimesterGoals, getYearlyGoals, numberStepIntro })(AddWeeklyGoalScreen);
