import axios from 'axios';
import { toast } from 'react-toastify';
import { setHeaders } from '../api/headers';

// Get all Weekly goals
export const getWeeklyGoals = () => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get('/api/goal/allWeeklygoals', setHeaders(token))
        .then(goals => {
            //console.log("TodoActions: ", todos);
            dispatch({
                type: "GET_WEEKLY_GOALS",
                goals
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}

// Get all Weekly goals with specific trimesterGoalID
export const getWeeklyGoalsWithTGoalID = (id) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get(`/api/goal/allWeeklygoals/${id}`, setHeaders(token))
        .then(goals => {
            //console.log("TodoActions: ", todos);
            dispatch({
                type: "GET_WEEKLY_GOALS_WITH_TRIMESTERGOALID",
                goals
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Create a new Weekly
export const addWeeklyGoal = (weeklyGoal, type) => (dispatch, getState) => {
    const uid = getState().auth.user._id;
    const token = getState().auth.user.token

    axios.post(`/api/goal/creategoal/${type}`, { ...weeklyGoal, uid }, setHeaders(token))
        .then(goal => {
            dispatch({
                type: "ADD_WEEKLY_GOAL",
                goal
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Update a Weekly goal
export const updateWeeklyGoal = (updatedWeeklyGoal, id, type) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.put(`/api/goal/updategoal/${type}/${id}`, updatedWeeklyGoal, setHeaders(token))
        .then(goal => {
            dispatch({
                type: "UPDATE_WEEKLY_GOAL",
                goal
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Delete a todo
export const deleteWeeklyGoal = (id, type) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.delete(`/api/goal/deletegoal/${type}/${id}`, setHeaders(token))
        .then(() => {
            dispatch({
                type: "DELETE_WEEKLY_GOAL",
                id
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


