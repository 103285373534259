import React, { useEffect, useState } from "react";
//translation
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import "./Hearder.css";
import ConfirmationModal from '../../modal/ConfirmationModal';

//import logo
import logoXs from "../../../img/logoXs.png";
import logoLg from "../../../img/logoLg.png";

import { Link } from "react-router-dom";
import { logout, restartChallenge } from "../../../actions/authActions";

import { connect } from "react-redux";

//jquery
import $ from "jquery";

//icons
import AppsIcon from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ReplayIcon from '@material-ui/icons/Replay';
import { Backdrop, CircularProgress } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Header({ logout, active, restartChallenge }) {
  

  const { t, i18n } = useTranslation("");
  const auth = useSelector(state => state.auth.user)

  const classes = useStyles();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [openBackDrop, setOpenBackdrop] = useState(false);

  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    //console.log(lang);
  };

  /*Menu show*/
  /* validate if oconstant exists*/
  const handleToogle = () => {
    $("#nav-menu").addClass("show-menu");
  };

  const handleClose = () => {
    $("#nav-menu").removeClass("show-menu");
  };
  const navLink = document.querySelectorAll(".nav__link");

  const headerViews = document.querySelectorAll(".header");

  useEffect(() => {
    const navMenu = document.getElementById("nav-menu"),
      navToggle = document.getElementById("nav-toggle"),
      navClose = document.getElementById("nav-close");

    const headerViews = document.querySelectorAll("#nav-menu");
    const body = $("body");

    $(".nav__toggle").on("click", function () {
      $("#nav-menu").addClass("show-menu");
    });
  }, []);

  const logoutHandler = () => {
    logout();
  };

  const restartHandler = () => {
    setOpenConfirmation(true)
    /*setOpenBackdrop(true);
    restartChallenge();*/
  }

  function displayConfirmNotification() {
    if ("serviceWorker" in navigator) {
      var options = {
        body: "You successfully subscribed to Notification services!",
      };

      navigator.serviceWorker.ready.then((swreg) => {
        swreg.showNotification("Successfully subscribed [SW]", options);
      });
    }
  }

  function askForNotificationPermission() {
    /*Notification.requestPermission((result) => {
        if (result !== 'granted') {
            console.log("Permission denied");
        } else {
          displayConfirmNotification();
        }
    });*/
    //alert("Hello ", Notification.permission);
    //displayConfirmNotification();
  }

  let online;
  if (!navigator.onLine) {
    online = false;
  } else {
    online = true;
  }

  useEffect(() => {
    setOpenBackdrop(false);
  }, [auth])

  return (
    <>
      <Backdrop className={classes.backdrop} open={openBackDrop} >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmationModal
        openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation}
        confirmationTitle="Recommencer le challenge"
        confirmationText="En recommençant le challenge, vous perdrez toutes les informations que vous avez inséré.
                          Etes-vous sur de vouloir recommencer le challenge?"
        component="Restart"
        idToDelete={idToDelete}
        setOpenBackdrop={setOpenBackdrop}
      />

      <div className="header scroll-header" id="header">
        <nav className="nav container">
          <a href="/" className="nav__logo">
            <img src={logoXs} alt="" className="nav__logo--xs" />
            <img src={logoLg} alt="" className="nav__logo--lg" />
          </a>
          <div className="nav__menu" id="nav-menu">
            <ul className="nav__list grid">
              <li className="nav__item">
                <Link
                  to="/"
                  className={`nav__link  ${active == "db" ? "active-link" : " "
                    }`}
                  onClick={handleClose}
                >
                  <i className="uil uil-dashboard nav__icon"></i>{" "}
                  {t("dashboard")}
                </Link>
              </li>

              <li className="nav__item" onClick={handleClose}>
                <Link
                  to="/routine"
                  className={`nav__link  ${active == "routine" ? "active-link" : " "
                    }`}
                >
                  <i className="uil uil-brightness-plus nav__icon"></i>{" "}
                  {t("routine")}
                </Link>
              </li>

              <li className="nav__item" onClick={handleClose}>
                <Link
                  to="/todo"
                  className={`nav__link  ${active == "todo" ? "active-link" : " "
                    }`}
                >
                  <i className="uil uil-clipboard-notes nav__icon"></i>
                  {t("todayTask")}
                </Link>
              </li>

              <li className="nav__item" onClick={handleClose}>
                <Link
                  to="/goal"
                  className={`nav__link  ${active == "goal" ? "active-link" : " "
                    }`}
                >
                  <i className="uil uil-crosshair nav__icon"></i>{" "}
                  {t("objectives.objective")}
                </Link>
              </li>

              <li className="nav__item" onClick={handleClose}>
                <Link
                  to="/tips"
                  className={`nav__link  ${active == "tips" ? "active-link" : " "
                    }`}
                >
                  <i className="uil uil-brightness-plus nav__icon"></i>{" "}
                  {t("outils")}
                </Link>
              </li>

              <li className="nav__item" onClick={handleClose}>
                <Link to="#" className="nav__link" onClick={logoutHandler}>
                  <i className="uil uil-sign-out-alt nav__icon"></i>{" "}
                  {t("logout")}
                </Link>
              </li>

              <li className="nav__item" onClick={handleClose}>
                <Link to="#" className="nav__link" onClick={restartHandler}>
                  <ReplayIcon />
                  <span className="nav__icon restart" >Recommencer</span>
                </Link>
              </li>
            </ul>

            <i
              className="uil uil-times nav__close"
              id="nav-close"
              onClick={handleClose}
            ></i>
          </div>

          <div className="nav__btns">
            {/* THEME change button 
          <i
            className="uil uil-english-to-chinese"
            onClick={() => handleClick("en")}
          ></i>

          <i
            className="uil uil-english-to-chinese"
            onClick={() => handleClick("fr")}
          ></i>
          <i className="uil uil-moon change-theme" id="theme-button"></i>*/}
            <div className="nav__toggle" id="nav-toggle">
              {/* <i className="uil uil-apps"></i> */}
              <AppsIcon />
            </div>
          </div>
        </nav>
        <div>
          {online == false ? (
            <>
              <h2>Vous etes actuellement hors ligne.</h2>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
export default connect(null, { logout, restartChallenge })(Header);
