import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import axios from "axios";

import "../CSS/ResetPasswordScreen.css";

//import logo
import logoXs from '../../../../img/logoXs.jpg'
import logoLg from '../../../../img/logoLg.png'

//translation
import { useTranslation } from "react-i18next";

const ResetPasswordScreen = ({ match, history }) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tokenParams = params.get('token');

    const auth = useSelector(state => state.auth.user);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [token, setToken] = useState(tokenParams);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    useEffect(() => {
    }, [history, auth]);

    const resetPasswordHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        if (password !== confirmPassword) {
            setPassword("");
            setConfirmPassword("");
            setTimeout(() => {
                setError("");
            }, 5000);
            return setError("Passwords don't match");
        }

        try {
            const { data } = await axios.put(
                `/api/auth/resetpassword/${token}`,
                {
                    password,
                },
                config
            );

            setSuccess("Mot de passe réinitialisé");

            setPassword("");
            setConfirmPassword("");
            setToken("");

        } catch (error) {
            setError(error.response.data.error);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    const { t, i18n } = useTranslation("");

    return (
        <div className="resetpassword-screen">
            <form
                onSubmit={resetPasswordHandler}
                className="login-screen__form" >

                <img src={logoLg} alt="" className="login__img" />
                <h3 className="login-screen__title">Réinitialisation du mot de passe</h3>
                {error && <span className="error-message">{error} </span>}
                <div>
                    {success ? (
                        <span className="success-message">
                            {success} <Link to="/login">Se connecter</Link>
                        </span>
                    ) : (
                        <>
                            <div className="form-group">
                                <label htmlFor="token">Token de réinitialisation:</label>
                                <input
                                    disabled
                                    type="text"
                                    required
                                    id="token"
                                    placeholder="Entez votre token de réinitialisation"
                                    autoComplete="true"
                                    value={token}
                                //onChange={(e) => setToken(e.target.value)}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Nouveau mot de passe:</label>
                                <input
                                    type="password"
                                    required
                                    id="password"
                                    placeholder="Entez votre nouveau mot de passe"
                                    autoComplete="true"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="confirmpassword">Confirmation mot de passe:</label>
                                <input
                                    type="password"
                                    required
                                    id="confirmpassword"
                                    placeholder="Confirmez vottre nouveau mot de passe"
                                    autoComplete="true"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)} />
                            </div>

                            <button type="submit" className="button button__submit">
                                Réinitialiser le mot de passe
                            </button>

                            <span className="register-screen__subtext">
                                Vous n'avez pas demandez de réinitialisation de mot de passe ? <Link to="/login">Se connecter</Link>
                            </span>
                        </>
                    )}
                </div>
            </form>
        </div>
    );
};

export default ResetPasswordScreen;